import type { ComponentProps } from 'react';
import ReactFastMarquee from 'react-fast-marquee';
import styles from './MarqueeSubscription/marqueeSubscription.module.scss';

export type MarqueeProps = ComponentProps<typeof ReactFastMarquee>;

export const Marquee = ({ children, style, ...props }: MarqueeProps) => (
    <ReactFastMarquee
        className={styles.marqueeContainer}
        speed={25}
        style={{ maxWidth: '100vw', overflowX: 'hidden', ...style }}
        {...props}>
        {children}
    </ReactFastMarquee>
);