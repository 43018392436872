import Head from 'next/head';
import type { ReactElement } from 'react';
import { useIntl } from 'react-intl';

export default function PageHead(): ReactElement {
    const intl = useIntl();

    return (
        <Head key={'pageHead'}>
            <title key={'pageTitle'}>
                {intl.formatMessage({ id: 'app_name' })}
            </title>
            <meta name="description" content="Access to Hallow prayer app" />
            <meta name="robots" content="none" />
            <link rel="icon" href="/favicon.ico" />
        </Head>
    );
}