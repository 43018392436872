import { apiGoogleAuth } from '@utilities/api';
import Button from '@components/Button/Button';
import { BUTTON_TYPE } from '@models/components';
import { showErrorMessage } from '@utilities/loggers';
import { SSO_BUTTON } from '@models/Auth';
import { useGoogleLogin } from '@react-oauth/google';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { checkErrorOrigin, throwError } from '@utilities/errors';
import type { Dispatch, SetStateAction } from 'react';
import { loginRedirect, onSSOFailure } from '@utilities/functions';

type Props = {
    additionalButtonClass?: string,
    additionalOnClick?: Function,
    buttonLoading: SSO_BUTTON,
    setButtonLoading: Dispatch<SetStateAction<SSO_BUTTON>>,
}

const ButtonGoogle = ({
    additionalButtonClass = null,
    additionalOnClick = null,
    buttonLoading,
    setButtonLoading
}: Props) => {
    const router = useRouter();
    const intl = useIntl();

    const completeGoogleLogin = async (res) => {
        const user = await apiGoogleAuth(res.code);
        if (throwError(user, intl, showErrorMessage, setButtonLoading, null) && checkErrorOrigin(user) !== 'apiPostCart') return;
    
        setButtonLoading(null);
        loginRedirect(router, intl, user, user.is_new);
    };
    
    const callUseGoogleLogin = useGoogleLogin({
        flow: 'auth-code',
        scope: 'profile email',
        onSuccess: (res) => completeGoogleLogin(res),
        onError: (err) => {
            showErrorMessage(intl.formatMessage({ id: 'login_social_auth_error_message', defaultMessage: 'We are unable to log you in. Please try again.' }), intl);
            onSSOFailure(err.error, intl, setButtonLoading);
        },
        onNonOAuthError: (err) => onSSOFailure(err, intl, setButtonLoading)
    });
    
    const chooseGoogle = () => {
        setButtonLoading(SSO_BUTTON.GOOGLE);
        if (additionalOnClick) additionalOnClick();
        callUseGoogleLogin();
    };

    return (
        <Button
            buttonIconSrc={'/logo_google_color.svg'}
            buttonType={BUTTON_TYPE.GOOGLE}
            buttonClass={additionalButtonClass ? additionalButtonClass : ''}
            disabled={!!buttonLoading}
            loading={buttonLoading === SSO_BUTTON.GOOGLE}
            onClick={chooseGoogle}
            text={'launch_continue_with_google'} />
    );
};

export default ButtonGoogle;