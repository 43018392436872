import { getCookieDomain } from './vercel';
import HallowCookies from '../models/Cookies';
import type { NextApiResponse } from 'next';
import { parse } from 'cookie';
import { serialize } from 'cookie';
import type { ServerResponse } from 'http';

/*
    Getters
*/

export const getCookie = (cookieHeader: string, name: string): string => {
    if (!cookieHeader) return null;
    const cookieObj = parse(cookieHeader);
    return cookieObj[name];
};

export const getApiCookie = (cookieHeader: string): string => {
    return getCookie(cookieHeader, HallowCookies.API_ENDPOINT);
};

export const getRefreshCookie = (cookieHeader: string): string => {
    return getCookie(cookieHeader, HallowCookies.REFRESH);
};

export const getAuthCookies = (cookieHeader: string): { refresh: string|null } => {
    const cookieObj = { refresh: null };

    cookieObj.refresh = getCookie(cookieHeader, HallowCookies.REFRESH);

    return cookieObj;
};

/*
    Setters
*/

export const addToHeader = (res: NextApiResponse|ServerResponse, serializedCookie: string): void => {
    let newCookieHeader;
    const curCookieHeader = res.getHeader('set-cookie');

    if (Array.isArray(curCookieHeader)) {
        newCookieHeader = curCookieHeader;
        newCookieHeader.push(serializedCookie);
    } else if (curCookieHeader !== undefined) {
        newCookieHeader = [curCookieHeader, serializedCookie];
    } else {
        newCookieHeader = serializedCookie;
    }
    res.setHeader('set-cookie', newCookieHeader);
};

export const setHTTPCookie = (name, value, expirationDate) => {
    return serialize(name, value, {
        domain: 'hallow.com',
        path: '/',
        httpOnly: true,
        sameSite: 'strict',
        secure: true,
        expires: expirationDate
    });
};

export const setAuthCookies = (res, data) => {
    const serializedRefresh = setHTTPCookie(HallowCookies.REFRESH, data.refresh_token, new Date('1/1/2060'));
    addToHeader(res, serializedRefresh);
};

export const setFeatureFlaggerCookie = (ldKey) => {
    if (!process.env.ACCESS_AUTH_HEADER) {
        document.cookie = `${ HallowCookies.LD_GUEST_KEY }=${ ldKey }; expires=${ new Date('1/1/2060') }; domain=.hallow.com; path=/;`;
    } else {
        const host = window?.location?.host;
        document.cookie = `${ HallowCookies.LD_GUEST_KEY }=${ ldKey }; expires=${ new Date('1/1/2060') }; domain=${ getCookieDomain(host) }; path=/;`;
    }
};

export const saveBrowserConsent = (consent) => {
    localStorage.setItem('consent', consent);
    if (process.env.ACCESS_AUTH_HEADER) document.cookie = `${ HallowCookies.CONSENT }=${ consent }; expires=${ new Date('1/1/2060') }; domain=${ getCookieDomain(window?.location?.host) }; path=/;`;
    else document.cookie = `${ HallowCookies.CONSENT }=${ consent }; expires=${ new Date('1/1/2060') }; domain=.hallow.com; path=/;`;
};

/*
    Delete
*/

export const removeAuthCookies = (res) => {
    // leaving this one in here because we should eliminate this cookie wherever we find it
    const serialized = setHTTPCookie(HallowCookies.TOKEN, 'logout', new Date('1/1/2000'));
    addToHeader(res, serialized);
        
    const serializedRefresh = setHTTPCookie(HallowCookies.REFRESH, 'logout', new Date('1/1/2000'));
    addToHeader(res, serializedRefresh);
};

export const removeFeatureFlaggerCookies = (cookieDomain = null) => {
    if (typeof document !== undefined) {
        document.cookie = `${ HallowCookies.LD_GUEST_KEY }=logout; expires=${ new Date('1/1/2000') }; domain=${ cookieDomain }; path=/;`;
    }
};