import type { ButtonHTMLAttributes } from 'react';
import { clsx } from 'clsx';

import styles from './button.module.scss';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: 'primary' | 'secondary'
};

export const Button = ({ children, className, variant = 'primary', ...props }: ButtonProps) => {
    return (
        <button className={clsx(styles.button, styles[variant], className)} {...props}>
            {children}
        </button>
    );
};