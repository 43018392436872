export enum ACCOUNT_TYPE {
    PHONE = 1,
    EMAIL = 2
}

export enum AUTH_STATE {
    USERNAME = 1,
    PASSWORD = 2,
    FIRST_LAST_NAME = 3
}

export enum AUTH_BUTTON {
    PHONE_CONTINUE = 'phone_continue',
    PHONE_SUBMIT = 'phone_submit',
    PHONE_CODE = 'phone_code',
    EMAIL_CONTINUE = 'email_continue',
    EMAIL_SUBMIT = 'email_submit',
    NAMES_FINISH = 'names_finish',
}

export enum SSO_BUTTON {
    APPLE = 'apple',
    GOOGLE = 'google'
}

export type PhoneObject = {
    phone: string,
    country_code: string,
    channel: string
}

export type PhoneAuth = {
    phone: string,
    otp: string,
    // captcha?: string // Backend does not accept
}

export type EmailAuth = {
    email: string,
    password: string,
    captcha?: string
}