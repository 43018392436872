export type EmptyPromise = Promise<void|void[]>;

export enum ICON_DIRECTION {
    UP = 'up',
    RIGHT = 'right',
    DOWN = 'down',
    LEFT = 'left'
}

export enum DEVICE_TYPES {
    DESKTOP = 'desktop',
    PHONE = 'phone',
    TABLET = 'tablet',
    UNKNOWN = 'unknown'
}