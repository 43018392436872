import { RadioIndicator } from '../RadioIndicator';
import styles from './RadioItemPromoCode.module.scss';
import { usePromoCode } from '../../../hooks';
import { RadioItem, type RadioItemProps } from '../RadioItem';

export type RadioItemPromoCodeProps = RadioItemProps & {
    promoCode: string;
}

export const RadioItemPromoCode = ({ className, promoCode, ...props }: RadioItemPromoCodeProps) => {
    const { getPromoCodeAmountIntl, getPromoCodeTrialLengthIntl, query } = usePromoCode({ promoCode });

    return (
        <RadioItem className={styles.radioItem} id={promoCode} value={promoCode} {...props}>
            <label className={styles.label}>
                {/* poor man's check to ensure user does not see 'Free 0 Days' flicker before AB test promo codes load */}
                {/* a shimmer state for this component (like we need for our checkout page) would be ideal */}
                {(!query.isLoading && !query.isError) && (
                    <>
                        <h2>{getPromoCodeAmountIntl({ override0WithFree: true })}</h2>
                        <span className={styles.span}>{getPromoCodeTrialLengthIntl({ daysPerMonthMultiplierThreshold: 1 })}</span>
                    </>
                )}
            </label>
            <div className={styles.radioIndicatorContainer}>
                <RadioIndicator />
            </div>
        </RadioItem>
    );
};