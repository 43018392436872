import deStrings from '@assets/languages/de.json';
import enphStrings from '@assets/languages/en-PH.json';
import enStrings from '@assets/languages/en.json';
import esStrings from '@assets/languages/es.json';
import frStrings from '@assets/languages/fr.json';
import itStrings from '@assets/languages/it.json';
import type { MessageFormatElement } from 'react-intl';
import plStrings from '@assets/languages/pl.json';
import ptStrings from '@assets/languages/pt.json';

// IMPROVE: these functions can probably operate off of an enum/array of our supported languages

export const formatLocale = (locale: string): string => {
    if (!locale) return null;

    let split = locale.split('-');
    if (split.length === 1) split = locale.split('_');
    if (split.length === 1) return locale;

    const [lang, country] = split;
    if (lang === 'en' && country.toLowerCase() === 'ph') return 'en-PH';
    return lang;
};

export const getLanguageKeys = (locale: string): Record<string, string> | Record<string, MessageFormatElement[]> => {
    switch (locale) {
        case 'de':
            return deStrings;
        case 'en-PH':
            return enphStrings;
        case 'es':
            return esStrings;
        case 'fr':
            return frStrings;
        case 'it':
            return itStrings;
        case 'pl':
            return plStrings;
        case 'pt':
            return ptStrings;
        default:
            return enStrings;
    }
};

export const supportedLocale = (val: string, defaultNull: boolean = false): string => {
    if (val.startsWith('de')) return 'de';
    else if (val.startsWith('es')) return 'es';
    else if (val.startsWith('fr')) return 'fr';
    else if (val.startsWith('it')) return 'it';
    else if (val.startsWith('pt')) return 'pt';
    else if (val.startsWith('pl')) return 'pl';
    else if (val.startsWith('en') && val.toLowerCase().endsWith('ph')) return 'en-PH';
    else if (defaultNull && val.startsWith('en')) return 'en';
    else if (defaultNull) return null;
    return 'en';
};

export const localeFromList = (list: Array<string>|readonly string[]): string => {
    const foundLocales = list.filter((opt) => supportedLocale(opt, true)).map((loc) => formatLocale(loc));
    if (foundLocales.includes('en-PH')) return 'en-PH';
    if (foundLocales.length) return foundLocales[0];
    return 'en';
};