import clsx from 'clsx';
import GooglePayIcon from '@components/icons/GooglePayIcon';
import s from '../walletPayButton.module.scss';
import { SCREEN_NAMES } from '@v2/utilities/enums/screenNames';
import { useAnalytics } from '@utilities/hooks/useAnalytics';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { selectPromoCode, selectTotal } from '@store/orderModel';

type Props = {
    containerClass?: string
    disabled?: boolean;
    stripe: any
}

const GooglePayButton = ({
    containerClass = null,
    disabled,
    stripe
}: Props) => {
    const analytics = useAnalytics();
    const router = useRouter();

    const promoCode = useSelector(selectPromoCode);
    const total = useSelector(selectTotal);
    
    const onClick = () => {
        stripe.showPaymentRequest();
        analytics.track({ event: 'Tapped GooglePay', properties: { screen_name: SCREEN_NAMES[router.pathname], promo_code: promoCode, total } });
    };

    if (stripe.showGooglePay) return (
        <button disabled={disabled} id="google-pay-button" className={clsx(s.walletPayButton, containerClass && containerClass)} onClick={onClick}>
            <GooglePayIcon />
        </button>
    );
    
    return null;
};

export default GooglePayButton;