import React from 'react';

type Props = {
    iconClass: string
    boxClass: string
    checkmarkClass: string
}

const CheckboxIcon = ({
    iconClass,
    boxClass,
    checkmarkClass
}: Props) => (
    <svg className={iconClass} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect className={boxClass} x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        <path className={checkmarkClass} d="M10 15l3 3l7 -7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default CheckboxIcon;