import { getLanguageKeys } from '@utilities/languages';
import { localeFromList } from '@utilities/languages';
import { selectLocale } from '@store/sessionModel';
import { store } from 'store';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { MessageFormatElement, IntlProvider as ReactIntlProvider } from 'react-intl';
import { useEffect, useState } from 'react';

const { dispatch, getState } = store;

const IntlProvider = ({ children }) => {
    const router = useRouter();
    const locale = useSelector(selectLocale);
    const [stringKeys, setStringKeys] = useState<Record<string, string> | Record<string, MessageFormatElement[]>>(getLanguageKeys(locale));

    useEffect(() => {
        router.events.on('routeChangeComplete', updateUrlLocale);
        return () => router.events.off('routeChangeComplete', updateUrlLocale);
    }, []);

    useEffect(() => {
        updateStringsLocale();
        updateUrlLocale(window?.location?.href.replace(`${ window?.location?.protocol }//${ window?.location?.host }`, ''));
    }, [locale]);
    
    const updateStringsLocale = () => {
        // get client-side locale
        let curLang = localeFromList(navigator.languages);

        // check for user locale
        const userLang = getState().user?.language;
        if (userLang) curLang = userLang;

        // update site locale
        setStringKeys(getLanguageKeys(curLang));
        if (curLang !== locale) dispatch.session.setLocale(curLang);
    };

    const updateUrlLocale = (route) => {
        const loc = getState().session.locale;
        if (!loc) return;

        if (route.includes('?')) route = route.substring(0, route.indexOf('?'));
        if (route.includes('#')) route = route.substring(0, route.indexOf('#'));

        let newRoute = route;

        // remove any locales from url
        if (newRoute.endsWith(`/${ loc }`)) newRoute = newRoute.slice(0, newRoute.length - loc.length );

        router.locales.forEach((l) => {
            if (l === loc) return;
            newRoute = newRoute.replace(`/${ l }/`, '');
            if (newRoute.endsWith(`/${ l }`)) newRoute = newRoute.substring(0, newRoute.length - l.length);
        });

        newRoute = newRoute.replace(`${ loc }/`, '');
        newRoute = newRoute.replace(`/${ loc }`, '');

        // add new locale to url
        if (loc === 'en') newRoute = `/${ newRoute }`;
        else newRoute = `/${ loc }/${ newRoute }`;

        newRoute = newRoute.replace('//', '/');
        if (newRoute.endsWith('/')) newRoute = newRoute.substring(0, newRoute.length - 1);

        if (newRoute === '' && route !== '/') window.location.replace(`${ window?.location?.protocol }//${ window?.location?.host }`);
        if (newRoute === route || newRoute === '') return;

        if (loc === 'en') window.location.replace(`${ window?.location?.protocol }//${ window?.location?.host }${ newRoute }`);
        router.replace(newRoute);
    };

    return (
        <ReactIntlProvider
            key={'intlProvider'}
            defaultLocale={'en'}
            locale={locale ?? 'en'}
            messages={stringKeys}>
            {children}
        </ReactIntlProvider>
    );
};

export default IntlProvider;