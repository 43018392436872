import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import s from './footer.module.scss';
import { COPYRIGHT_URL, PRIVACY_URL, TERMS_OF_SERVICE_URL } from '@utilities/urls';

type Props = {
    footerClass?: string
    footerRef: React.MutableRefObject<HTMLElement>
}

const Footer = ({ footerClass, footerRef }: Props) => {
    const date = (new Date).getFullYear();

    return (
        <footer ref={footerRef} id="site-footer" className={clsx(s.footer, footerClass && footerClass)}>
            <div className={s.footerName}>
                <FormattedMessage id={'web_footer_copyright_symbol'} defaultMessage={`© ${ date } Hallow, Inc.`} values={{ 0: date }} />
            </div>
            <div className={s.footerButtons}>
                <a
                    href="#cookie-manager"
                    className={s.footerButton}
                >
                    <FormattedMessage id={'cookie_manager_manage_button'} defaultMessage={'Manage Cookies'} />
                </a>
                <a
                    href={PRIVACY_URL}
                    target="_blank"
                    className={s.footerButton}
                    rel="noreferrer"
                >
                    <FormattedMessage id={'settings_privacy'} defaultMessage={'Privacy Policy'} />
                </a>
                <a
                    href={TERMS_OF_SERVICE_URL}
                    target="_blank"
                    className={s.footerButton}
                    rel="noreferrer"
                >
                    <FormattedMessage id={'settings_terms'} defaultMessage={'Terms of Service'} />
                </a>
                <a
                    href={COPYRIGHT_URL}
                    target="_blank"
                    className={s.footerButton}
                    rel="noreferrer"
                >
                    <FormattedMessage id={'copyright_button_title'} defaultMessage={'Copyright'} />
                </a>
            </div>
        </footer>
    );
};

export default Footer;