import ArrowIcon from '@components/icons/ArrowIcon/ArrowIcon';
import clsx from 'clsx';
import CreditCardIcon from '@components/icons/CreditCardIcon/CreditCardIcon';
import { FormattedMessage } from 'react-intl';
import type { HallowStripeObject } from '@models/PaymentProcessors';
import s from './stripeCardFormDropdown.module.scss';
import StripeCard from '@components/PaymentProcessors/StripeCard/StripeCard';
import React, { Dispatch, SetStateAction, useState } from 'react';

type Props = {
    additionalContainerClass?: string
    additionalDropdownState: Dispatch<SetStateAction<boolean>>
    additionalLoadingState: Dispatch<SetStateAction<boolean>>
    additionalOnOpenDropdown?: () => void
    disabled?: boolean;
    stripe: HallowStripeObject
    submitButtonText: string
}

const StripeCardFormDropdown = ({
    additionalContainerClass = null,
    additionalDropdownState,
    additionalLoadingState,
    additionalOnOpenDropdown = null,
    disabled,
    stripe,
    submitButtonText
}: Props) => {
    const [isCardDropdownOpen, setIsCardDropdownOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const setLoadingState = (forceState = null) => {
        setIsLoading(forceState ?? !isLoading);
        if (additionalLoadingState) additionalLoadingState(forceState ?? !isLoading);
    };

    const setDropdownState = () => {
        setIsCardDropdownOpen(!isCardDropdownOpen);
        if (additionalDropdownState) additionalDropdownState(!isCardDropdownOpen);
        if (additionalOnOpenDropdown) additionalOnOpenDropdown();
    };

    return (
        <div className={clsx(s.cardDropdownContainer, additionalContainerClass && additionalContainerClass)}>
            <button className={s.cardDropdownButton} disabled={disabled} onClick={() => setDropdownState()}>
                <ArrowIcon iconClass={clsx(s.arrowIcon, isCardDropdownOpen && s.cardDropdownOpen)} />
                <span className={s.buttonTextContainer}>
                    <CreditCardIcon iconClass={s.cardIcon} />
                    <p>
                        <FormattedMessage id={'billing_screen_credit_or_debit'} defaultMessage={'Credit or Debit Card'} />
                    </p>
                </span>
            </button>
            { isCardDropdownOpen
                && <div className={s.cardDropdownContent}>
                    <StripeCard
                        stripe={stripe}
                        setIsLoading={setLoadingState}
                        isLoading={isLoading}
                        submitButtonText={submitButtonText} />
                </div>
            }
        </div>
    );
};

export default StripeCardFormDropdown;