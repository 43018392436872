import Bugsnag from '@bugsnag/js';
import { USER_CONSENT } from '@models/store';
import { useSelector } from 'react-redux';
import BugsnagPluginReact, { BugsnagErrorBoundary as BugsnagBoundary } from '@bugsnag/plugin-react';
import React, { useEffect } from 'react';
import { selectUser, selectUserConsent } from '@store/userModel';

/*
    Setup Bugsnag
*/
Bugsnag.start({
    apiKey: process.env.ACCESS_BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact],
    releaseStage: process.env.ACCESS_BUGSNAG_ENV,
    onUnhandledRejection: (err) => {
        if (/Object Not Found Matching Id/g.test(JSON.stringify(err))) return false;
    },
    onUncaughtException: (err) => {
        if (/Object Not Found Matching Id/g.test(JSON.stringify(err))) return false;
    },
    onError: (event) => {
        if (/Object Not Found Matching Id/g.test(JSON.stringify(event))) return false;
        if (typeof localStorage !== undefined && localStorage.getItem('consent')) {
            return JSON.parse(localStorage.getItem('consent'))?.includes(USER_CONSENT.TRACK_ANALYTICS);
        }
        return true;
    },
    autoTrackSessions: false
});

const ErrorBoundary: BugsnagBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const BugsnagErrorBoundary = ({ children }) => {
    const user = useSelector(selectUser);
    const userConsent = useSelector(selectUserConsent);

    useEffect(() => {
        Bugsnag.setUser(user.id as unknown as string, user?.email ?? '', user?.name ?? '');
    }, [user]);

    useEffect(() => {
        if (typeof userConsent?.includes === 'function' && userConsent?.includes(USER_CONSENT.TRACK_ANALYTICS)) Bugsnag.resumeSession();
        else Bugsnag.pauseSession();
    }, [userConsent]);

    return <ErrorBoundary>{ children }</ErrorBoundary>;
};

export default BugsnagErrorBoundary;