import { api } from '@pages/_app';
import { isApiError } from './errors';
import { logError } from './loggers';
import network from '@utilities/network';
import requestIp from 'request-ip';
import { store } from '@store';
import { addConsent, addUtms, onUserAuth } from './functions';
import type { ApiCart, ApiCartBody, ApiNewSubscription, ApiPlan, ApiResetPasswordReq } from '@models/api/apiHallow';
import type { ApiOauth, ApiOtp, ApiUser, ApiUserAuth } from '@models/api';
import type { EmailAuth, PhoneAuth, PhoneObject } from '@models/Auth';
import { isIapUpgrade, resetStoreOrder } from './subscriptions';
import { getRefreshCookie } from '@utilities/cookies';

const { dispatch, getState } = store;

/*
    Auth Endpoints
*/

export const apiOtp = async (
    phoneObj: PhoneObject
): Promise<ApiOtp> => {
    try {
        const { data } = await network.post<ApiOtp>(`${ api.url }/otp`, phoneObj);
        if (isApiError(data)) throw data;

        return data;
    } catch (err) {
        logError('apiOtp', err);
        return err;
    }
};

export const apiEmailExists = async (
    email
): Promise<boolean> => {
    try {
        const reqBody = { email };

        const { data } = await network.post<boolean>(`${ api.url }/exists`, reqBody);
        if (isApiError(data)) throw data;

        return data;
    } catch (err) {
        logError('apiEmailExists', err);
        return err;
    }
};

export const apiForgotPassword = async (
    email
): Promise<boolean> => {
    try {
        const reqBody = { email };

        const { data } = await network.post<boolean>(`${ api.url }/password/forgot`, reqBody);
        if (isApiError(data)) throw data;

        return data;
    } catch (err) {
        logError('apiForgotPassword', err);
        return err;
    }
};

export const apiResetPassword = async (
    reqBody: ApiResetPasswordReq
): Promise<ApiUser> => {
    try {
        const { data } = await network.post<ApiUser>(`${ api.url }/password/reset`, reqBody);
        if (isApiError(data)) throw data;

        return data;
    } catch (err) {
        logError('apiResetPassword', err);
        return err;
    }
};

export const apiRegister = async (
    phoneObj: PhoneAuth = null,
    emailObj: EmailAuth = null
): Promise<any> => {
    let reqBody: any = {};

    if (phoneObj) {
        reqBody.phone = phoneObj.phone;
        reqBody.otp = phoneObj.otp;
    }
    if (emailObj) {
        reqBody.email = emailObj.email;
        reqBody.password = emailObj.password;
        if (emailObj?.captcha) reqBody.captcha = emailObj.captcha;
    }

    reqBody = addUtms(reqBody);
    reqBody = addConsent(reqBody);

    try {
        const { data } = await network.post<ApiUserAuth>('/api/register', reqBody);
        if (isApiError(data)) throw data;

        const errorData = await onUserAuth(data);
        if (isApiError(errorData)) throw errorData;

        return data;
    } catch (err) {
        logError('apiRegister', err);
        return err;
    }
};

export const apiPhoneAuth = async (
    phoneAuth: PhoneAuth
): Promise<ApiUserAuth> => {
    const url = `${ api.url }/login/phone`;
    const callName = 'postPhoneLogin';
    let reqBody: any = {
        phone: phoneAuth.phone,
        otp: phoneAuth.otp
    };

    if (sessionStorage.getItem('hasConsentUpdated')) reqBody = addConsent(reqBody);

    try {
        const { data } = await network.post<ApiUserAuth>('/api/login', { url, callName, reqBody });
        if (isApiError(data)) throw data;

        const errorData = onUserAuth(data);
        if (isApiError(errorData)) throw errorData;

        return data;
    } catch (err) {
        logError(callName, err);
        return err;
    }
};

export const apiEmailAuth = async (
    emailAuth: EmailAuth
): Promise<ApiUserAuth> => {
    const url = `${ api.url }/login`;
    const callName = 'postEmailLogin';
    let reqBody: any = {
        email: emailAuth.email,
        password: emailAuth.password,
    };

    if (sessionStorage.getItem('hasConsentUpdated')) reqBody = addConsent(reqBody);

    try {
        const { data } = await network.post<ApiUserAuth>('/api/login', { url, callName, reqBody });
        if (isApiError(data)) throw data;

        const errorData = await onUserAuth(data);
        if (isApiError(errorData)) throw errorData;

        return data;
    } catch (err) {
        logError(callName, err);
        return err;
    }
};

export const apiAppleAuth = async (
    token,
    name?,
    last_name?
): Promise<ApiUserAuth> => {
    const url = `${ api.url }/social/apple`;
    const callName = 'postAppleLogin';
    let reqBody: any = { token, redirect: process.env.ACCESS_APPLE_REDIRECT_URI };
    if (name) reqBody.name = name;
    if (last_name) reqBody.last_name = last_name;

    reqBody = addConsent(reqBody, true);
    reqBody = addUtms(reqBody);

    try {
        const { data } = await network.post<ApiUserAuth>('/api/login', { url, callName, reqBody });
        if (isApiError(data)) throw data;

        const errorData = onUserAuth(data);
        if (isApiError(errorData)) throw errorData;

        return data;
    } catch (err) {
        logError(callName, err);
        return err;
    }
};

export const apiGoogleAuth = async (
    code
): Promise<ApiUserAuth> => {
    const url = `${ api.url }/social/google`;
    const callName = 'postGoogleLogin';
    let reqBody: any = { code };

    reqBody = addConsent(reqBody, true);
    reqBody = addUtms(reqBody);

    try {
        const { data } = await network.post<ApiUserAuth>('/api/login', { url, callName, reqBody });
        if (isApiError(data)) throw data;

        const errorData = onUserAuth(data);
        if (isApiError(errorData)) throw errorData;

        return data;
    } catch (err) {
        logError(callName, err);
        return err;
    }
};

export const apiLogout = async (): Promise<boolean> => {
    try {
        dispatch.user.resetUserState();
        await resetStoreOrder();

        const { data } = await network.post<boolean>('/api/logout', { token: getState().user?.oauth?.access_token });
        if (isApiError(data)) throw data;

        return data;
    } catch (err) {
        logError('apiLogout', err);
        return err;
    }
};

export const apiAuthRefresh = async (
    refreshToken = undefined,
    serverOrigin = '',
    skipPostCart = false,
): Promise<ApiOauth> => {
    const reqBody = { refresh_token: refreshToken };

    try {
        const { data } = await network.post<ApiOauth>(`${ serverOrigin }/api/oauth/refresh`, reqBody);
        if (isApiError(data)) throw data;

        dispatch.user.updateUserState({ oauth: data });

        await apiGetPlans();

        if (!skipPostCart) {
            const cartBody: ApiCartBody = { price_id: getState().order?.plan?.price.id };
            if (getState().order?.code?.code) cartBody.promo_code = getState().order.code.code;
            await apiPostCart(cartBody);
        }

        return data;
    } catch (err) {
        logError('apiAuthRefresh', err);
        return err;
    }
};

export const serverAuthRefresh = async (
    req,
    refreshToken
) => {
    const callBody: any = { secret: process.env.ACCESS_SECRET, refresh_token: refreshToken };
    const callConfig: any = { headers: {} };
    const clientIp = requestIp.getClientIp(req);
    if (clientIp) callConfig.headers['X-Forwarded-For'] = clientIp;
    if (req.headers['x-vercel-ip-country']) callConfig.headers['X-Vercel-Ip-Country'] = req.headers['x-vercel-ip-country'];
    if (req.headers['accept-language']) callConfig.headers['Accept-Language'] = req.headers['accept-language'];
    return (await network.post<ApiOauth>(`${api.url}/oauth/refresh`, callBody, callConfig)).data;
};

/*
    User Endpoints
*/

export const apiGetUserData = async (token?: string): Promise<ApiUser> => {
    dispatch.user.setIsUserUpdating(true);
    
    try {
        const { data } = await network.get<ApiUser>(`${ api.url }/me`, token ? { headers: { Authorization: `Bearer ${token}` } } : {});
        if (isApiError(data)) throw data;

        dispatch.user.updateUserState(data);

        return data;
    } catch (err) {
        logError('apiGetUserData', err);
        if (err?.error === 'Token not found') dispatch.user.resetUserState();
        dispatch.user.setIsUserUpdating(false);
        return err;
    }
};

export const apiUpdateUser = async (
    user: Partial<ApiUser>,
): Promise<ApiUser> => {
    try {
        const { data } = await network.put<ApiUser>(`${ api.url }/me`, user);
        if (isApiError(data)) throw data;

        dispatch.user.updateUserState(data);

        return data;
    } catch (err) {
        logError('apiUpdateUser', err);
        return err;
    }
};

/*
    Product Endpoints
*/

export const apiGetOldPlans = async (): Promise<Array<ApiPlan>> => {
    try {
        const { data } = await network.get<Array<ApiPlan>>(`${ api.url }/plans`);
        if (isApiError(data)) throw data;

        const annualPlan = data?.filter((plan) => plan?.product_id === 'yearly_full_subscription')[0];
        dispatch.order.setCurrentTrialOffer(annualPlan?.trial_days || 14);

        return data;
    } catch (err) {
        logError('apiGetOldPlans', err);
        return err;
    }
};

export const apiGetPlans = async (): Promise<Array<ApiPlan>> => {
    try {
        const { data } = await network.get<Array<ApiPlan>>(`${ api.url }/products/plans`);
        if (isApiError(data)) throw data;

        dispatch.session.updatePlans(data);

        return data;
    } catch (err) {
        logError('apiGetPlans', err);
        return err;
    }
};

export const apiPostCart = async (
    body: any,
): Promise<ApiCart> => {
    // There is never any reason to call cart with `free_subscription` and doing so returns conflicting data
    if (getState()?.order?.plan?.external_id === 'free_subscription') body.price_id = getState().session?.defaultApiPlan?.price.id;

    if (typeof window !== 'undefined' && window.location.href.includes('/onboarding/subscription')) return;

    try {
        const { data } = await network.post<ApiCart>(`${ api.url }/cart`, body);
        if (isApiError(data)) throw data;

        dispatch.order.updateOrderState(data);

        return data;
    } catch (err) {
        logError('apiPostCart', err);
        return err;
    }
};

export const apiPostSubscription = async (
    body: any,
): Promise<ApiNewSubscription> => {
    body.price_id = getState().order.plan?.price.id;
    const code = getState().order.code?.code;
    if (code) body.promo_code = code;

    const address = getState().order.address;
    if (address && Object.keys(address).length > 0)
        body.address = {
            city: address.city,
            country: address.country,
            line1: address.line1,
            line2: address.line2,
            metadata: address.metadata,
            postal_code: address.postalCode,
            state: address.state
        };

    const subscriptionPayAnyAmount = getState().session?.subscriptionPayAnyAmount ?? null;
    const subscriptionPayAnyAmountPriceId = getState().session?.subscriptionPayAnyAmountPriceId ?? body.price_id;

    try {
        let data;
        if (subscriptionPayAnyAmount !== null) {
            const dataObj = await network.post<ApiNewSubscription>(`${ api.url }/subscription/pay-any-amount`, {
                amount: subscriptionPayAnyAmount,
                payment_method: body.payment_method,
                payment_platform: body.payment_platform,
                price_id: subscriptionPayAnyAmountPriceId
            });
            data = dataObj.data;
        }
        else if (isIapUpgrade(getState().user?.subscription)) {
            const dataObj = await network.put<ApiNewSubscription>(`${ api.url }/subscription/iap-upgrade`, body);
            data = dataObj.data;
        } else {
            const dataObj = await network.post<ApiNewSubscription>(`${ api.url }/subscription`, body);
            data = dataObj.data;
        }
        if (isApiError(data)) throw data;

        apiGetUserData();
        // if family sub, upgrade family object (access does not use/have)

        // return total for analytics events
        data.total = getState().order?.cart?.total;

        resetStoreOrder(data);

        return data;
    } catch (err) {
        logError('apiPostSubscription', err);
        return err;
    }
};