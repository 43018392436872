import { useState } from 'react';

export type UsePaymentsProps = {};

export const UsePayments = () => {
    const [isPaypalPopupOpen, setIsPaypalPopupOpen] = useState(false);
    const [isStripeCardFormOpen, setIsStripeCardFormOpen] = useState(false);

    const togglePaypal = () => setIsPaypalPopupOpen(!isPaypalPopupOpen);

    const toggleStripe = () => setIsStripeCardFormOpen(!isStripeCardFormOpen);

    const isPaypalOrStripeOpen = isPaypalPopupOpen || isStripeCardFormOpen;
    const showPaypal = !isStripeCardFormOpen;
    const showStripe = !isPaypalPopupOpen;

    return {
        isPaypalPopupOpen,
        isPaypalOrStripeOpen,
        isStripeCardFormOpen,
        setIsPaypalPopupOpen,
        setIsStripeCardFormOpen,
        showPaypal,
        showStripe,
        togglePaypal,
        toggleStripe
    };
};