import Bugsnag from '@bugsnag/js';
import { isApiError } from './errors';
import { NextRouter } from 'next/router';
import type { OnboardingContextShape } from '@providers/OnboardingProvider/OnboardingContextShape';
import { PaymentPlatforms } from '@models/PaymentProcessors';
import { PROMO_CODE_GROUP } from '@models/Codes';
import { SCREEN_NAMES } from '@v2/utilities/enums/screenNames';
import { selectUser } from '@store/userModel';
import { showErrorMessage } from './loggers';
import { store } from '@store/store';
import { apiGetPlans, apiPostCart, apiPostSubscription } from './api';
import type { ApiNewSubscription, ApiSubscription } from '@models/api';
import { formatHallowPaypalPayment, formatHallowStripePayment } from './formatters';
import { selectCodeEndDate, selectCodeGroup, selectHadPreviousTrial, selectOneTimePayment, selectPaymentRequired, selectTrialDays } from '@store/orderModel';

declare global {
    interface Window {
        analytics: any // add analytics to access Segment globally
        hallowAnonymousUserId: string // add hallowAnonymousUserId to pass anon id across subdomains
    }
}

const { dispatch, getState } = store;

export const applyPromoCode = async (promoCode = null, intl, screenName = null, analytics = null) => {
    try {
        const newCart = await apiPostCart({ price_id: getState().order?.plan?.price.id, promo_code: promoCode });
        if (isApiError(newCart)) throw newCart;

        if (screenName && analytics) analytics.track({ event: 'Entered promo code', properties: { screen_name: SCREEN_NAMES[screenName], promo_code: promoCode } });

        return newCart;
    } catch (err) {
        showErrorMessage((err?.errors && err?.errors[0]?.error) ?? err.error ?? err, intl);
        return err;
    }
};

export const isRedeemEligible = (curUser = null) => {
    const codeFirstTrialOnly = selectHadPreviousTrial(getState());
    const codeGroup = selectCodeGroup(getState());
    
    if (!codeFirstTrialOnly) return true;

    if (codeGroup === PROMO_CODE_GROUP.GIFT_CARD || codeGroup === PROMO_CODE_GROUP.HDP) return true;

    const user = curUser ?? selectUser(getState());
    if (user?.subscription || user?.has_expired_subscription) return false;

    return true;
};

export const processRedeemOffer = async (router, intl) => {
    if (!isRedeemEligible()) return router.push('/redeem/ineligible');

    if (selectPaymentRequired(getState())) return router.push('/products/checkout');
    
    try {
        // ensure we have plans (a local price_id)
        if (!getState().session.plans) {
            const products = await apiGetPlans();
            if (isApiError(products)) throw products;
        }

        const subscription = await processSubscription();
        if (isApiError(subscription)) throw subscription;

        if (router.pathname === '/onboarding/sb/subscribe') return router.push({ pathname: '/onboarding/sb/preview ' });

        return router.push({ pathname: '/redeem/congrats', query: { code: subscription.promo } }, '/redeem/congrats' );
    } catch (err) {
        // check for failed gift card application error (due to active, paid subscription)
        if (err?.code === 3003) return router.push('/redeem/ineligible/active-subscription');

        showErrorMessage(err?.message ?? err?.error ?? err, intl);
        return router.push('/products/checkout');
    }
};

export const processSubscription = async (paymentProcessor = null, payment = null, email = null) => {
    let paymentDetails = {};

    if (paymentProcessor === PaymentPlatforms.STRIPE) paymentDetails = formatHallowStripePayment(payment, email);
    else if (paymentProcessor === PaymentPlatforms.PAYPAL) paymentDetails = formatHallowPaypalPayment(payment, email);

    const subscription = await apiPostSubscription(paymentDetails);
    if (isApiError(subscription)) Bugsnag.notify(subscription?.error); // views handle showing error alert

    return subscription;
};

export const isIapUpgrade = (subscription: ApiSubscription) => {
    if (!subscription) return false;

    return (subscription.type === 'amazon'
        || subscription.type === 'apple'
        || subscription.type === 'google')
        && new Date(subscription.end_date) >= new Date;
};

export const resetStoreOrder = (newSubData = null) => {
    if (newSubData) {
        const codeEndDate = selectCodeEndDate(getState());
        const codeGroup = selectCodeGroup(getState());
        const oneTimePayment = selectOneTimePayment(getState());
        const trialDays = selectTrialDays(getState());
        const { total, subtotal } = getState().order?.cart ?? {};
        const currency = getState().order.plan?.price.currency;
        dispatch.session.setRedeemedSubDetails({ codeEndDate, codeGroup, oneTimePayment, trialDays, total, subtotal, currency });
    }

    dispatch.order.setCode({});

    let priceId;
    if (newSubData.product === 'free_subscription') priceId = getState().session.defaultApiPlan?.price.id;
    else priceId = newSubData?.price_id;

    apiPostCart({ price_id: priceId });
};

export const handleSubscriptionSuccess = (subData: ApiNewSubscription, router: NextRouter, analytics, onboardingContext: OnboardingContextShape = null) => {
    // is this still necessary for web app / if user comes from web app?
    window?.opener?.postMessage('hallowRefreshUserData', process.env.ACCESS_URL_WEB_APP);

    if (analytics) analytics.track({
        event: 'Subscription Purchased',
        properties: {
            product: subData?.product,
            total: subData?.total
        }
    });

    let { subscribeRedirect } = router.query;
    if (Array.isArray(subscribeRedirect)) subscribeRedirect = subscribeRedirect[0];

    if (subscribeRedirect) {
        return router.push(subscribeRedirect);
    }

    if (router.pathname === '/onboarding/sb/subscribe') return router.push({ pathname: '/onboarding/sb/preview ' });

    if (onboardingContext) onboardingContext.navNextScreen();
    else router.push({ pathname: '/redeem/congrats', query: { code: subData.promo } }, '/redeem/congrats');
};