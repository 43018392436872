import * as device from 'react-device-detect';
import { alignAnonymousids } from '@utilities/functions';
import { dispatch, store } from '@store/store';
import type { EmptyPromise } from '@models/General';
import { segment } from '@pages/_app';
import { selectUserConsent } from '@store/userModel';
import { USER_CONSENT } from '@models/store/user';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { deviceDetectToDeviceType, getDeviceScreen, getDeviceUser } from '..';
import { useEffect, useState } from 'react';

type AnalyticsEvent = {
    event: string
    properties?: any
    options?: any
}

type AnalyticsHook = {
    track: (event: AnalyticsEvent) => EmptyPromise
};

export const useAnalytics = (): AnalyticsHook => {
    const [segmentInitialized, setSegmentInitialized] = useState<boolean>(false);
    const [eventQueue, setEventQueue] = useState<Array<AnalyticsEvent>>([]);

    const router = useRouter();
    const userConsent = useSelector(selectUserConsent);
    const utmCampaign = dispatch.session.utm_campaign;
    const utmMedium = dispatch.session.utm_medium;
    const utmSource = dispatch.session.utm_source;

    useEffect(() => { checkSegmentInit(); }, [segment]);

    const eventProperties = () => {
        const { screenHeight, screenWidth } = getDeviceScreen();

        return {
            browser: getDeviceUser(),
            device_type: deviceDetectToDeviceType(device.deviceType),
            locale: store?.getState()?.session?.locale ?? 'en',
            mobile: device.isMobile,
            platform: 'web',
            screen_height: screenHeight,
            screen_width: screenWidth,
            slug: `${ window.location.pathname }${ window.location.search }${ window.location.hash }`,
            utm_campaign: router?.query?.utm_campaign ?? utmCampaign ?? null,
            utm_medium: router?.query?.utm_medium ?? utmMedium ?? null,
            utm_source: router?.query?.utm_source ?? utmSource ?? null
        };
    };

    const checkSegmentInit = () => {
        if (!segmentInitialized) {
            if (!segment) return false;

            window.analytics = segment;
            segment.setAnonymousId(alignAnonymousids());

            setSegmentInitialized(true);

            eventQueue.forEach((event) => {
                setEventQueue([eventQueue.shift()]);
                track(event);
            });
        }

        return true;
    };

    const track = async (event: AnalyticsEvent) => {
        if (typeof userConsent?.includes === 'function' && userConsent?.includes(USER_CONSENT.TRACK_ANALYTICS)) {
            const isInit = checkSegmentInit();

            const fullEvent = {
                event: event.event,
                properties: {
                    ...eventProperties(),
                    ...event.properties
                }
            };

            if (isInit) segment.track(fullEvent.event, fullEvent.properties);
            else setEventQueue([...eventQueue, fullEvent]);
        }
    };

    return { track };
};