/*
    Hallow Payment Objects
*/

import { PaymentPlatforms } from '@models/PaymentProcessors';

export const formatHallowStripePayment = (paymentMethod, email) => {
    const paymentDetails: any = {
        payment_platform: PaymentPlatforms.STRIPE,
        payment_method: paymentMethod.id, // " ?? paymentMethod" <-- might be necessary, portal checks: if no id, use obj
        email
    };

    const paymentMethodAddress = paymentMethod?.billing_details?.address;
    if (paymentMethodAddress) {
        const address: any = {};

        if (paymentMethodAddress?.country) address.country = paymentMethodAddress?.country;
        else if (paymentMethod?.card?.country) address.country = paymentMethod?.card?.country;
        if (paymentMethodAddress?.postal_code) address.postal_code = paymentMethodAddress?.postal_code;
        if (paymentMethodAddress?.state) address.state = paymentMethodAddress?.state;

        if (Object.keys(address).length !== 0) paymentDetails.address = address;
    }

    return paymentDetails;
};

export const formatHallowPaypalPayment = (paymentMethod, email) => {
    const paymentDetails: any = {
        payment_method: paymentMethod.nonce, // " ?? payment" <-- might be necessary, portal checks: if no nonce, use obj
        payment_platform: PaymentPlatforms.PAYPAL,
        email
    };

    const paymentMethodAddress = paymentMethod?.details?.shippingAddress;
    if (paymentMethodAddress) {
        const address: any = {};

        if (paymentMethod?.details?.countryCode) address.country = paymentMethod.details.countryCode;
        else if (paymentMethodAddress?.countryCode) address.country = paymentMethodAddress.countryCode;
        if (paymentMethodAddress?.postalCode) address.postal_code = paymentMethodAddress.postalCode;
        if (paymentMethodAddress?.state) address.state = paymentMethodAddress.state;

        if (Object.keys(address).length !== 0) paymentDetails.address = address;
    }

    return paymentDetails;
};