import { clsx } from 'clsx';
import { HTMLAttributes } from 'react';

import styles from './onboardingProgress.module.scss';

export type OnboardingProgressProps = HTMLAttributes<HTMLDivElement> & {
    progress?: number;
};

export const OnboardingProgress = ({ className, progress = 0, ...props }: OnboardingProgressProps) => (
    <div className={clsx(styles.div, className)} {...props}>
        <div className={styles.progress} style={{ width: `${ progress * 100 }%` }} />
    </div>
);