import CheckboxIcon from '@components/icons/CheckboxIcon';
import clsx from 'clsx';
import s from './consentCheckbox.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';

type Props = {
    description: string
    disabled: boolean
    id: string
    isChecked: boolean
    onCheck: (isChecked: boolean, value: number) => void
    title: string
    value: number
}

const ConsentCheckbox = ({
    description,
    disabled,
    id,
    isChecked,
    onCheck,
    title,
    value
}: Props) => {
    const intl = useIntl();
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    
    const toggleDescription = () => setIsExpanded(!isExpanded);

    const toggleCheck = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!disabled) onCheck(!isChecked, value);
    };

    return (
        <div className={s.consentCheckboxContainer}>
            <div className={s.consentCheckboxBar}>
                <div className={s.consentCheckbox} onClick={(e) => toggleCheck(e)}>
                    <input
                        id={id}
                        className={s.consentCheckboxInput}
                        type="checkbox"
                        checked={isChecked}
                        disabled={disabled}
                        onChange={(e) => toggleCheck(e)}
                    />
                    <CheckboxIcon
                        iconClass={s.checkboxIcon}
                        boxClass={s.box}
                        checkmarkClass={s.checkmark}
                    />
                    <label htmlFor={id} className={s.consentCheckoutLabel} onClick={(e) => toggleCheck(e)}>
                        <FormattedMessage id={title} defaultMessage={'Checkbox'} />
                    </label>
                </div>
                <div className={s.expander} onClick={toggleDescription}>
                    <img
                        src={'/angle_up_black.svg'}
                        alt={intl.formatMessage({ id: 'angle_button_alt_description', defaultMessage: 'Angle up Icon' })}
                        className={clsx(s.angleIcon, isExpanded && s.open)}
                    />
                </div>
            </div>
            {isExpanded && (
                <div className={s.checkboxDescriptionContainer}>
                    <p className={s.checkboxDescription}>
                        <FormattedMessage id={description} defaultMessage={'Checkbox Description'} />
                    </p>
                </div>
            )}
        </div>
    );
};

export default ConsentCheckbox;