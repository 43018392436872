import { DATES_LOKALISE_KEYS } from '@models/Dates';

export const cleanDateString = (string: string) => new Date(`${ string.replace('Z', '').split('T')[0] }T00:00:00`);

// TODO: Improve to support intl date formats
export const getDateDisplayString = (date: Date) => date.toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' });

// use consistent locale to ensure consistent key lookup
// IF date is key date, return lokalise key (ex. EASTER)
// ELSE return display string
export const getDateDisplayKeyOrString = (dateString: string): string => {
    const date = cleanDateString(dateString);
    return DATES_LOKALISE_KEYS[date.toLocaleDateString('en-US')] || getDateDisplayString(date);
};