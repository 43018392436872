import type { CreatePaymentMethodData, PaymentMethodResult } from '@stripe/stripe-js';

export enum PAYPAL_INTENTS {
    GIFT = 'capture',
    SUBSCRIPTION ='subscription'
}

export type HallowStripeObject = {
    createPaymentMethod: (paymentMethodData: CreatePaymentMethodData) => Promise<PaymentMethodResult>
    showApplePay: boolean
    showGooglePay: boolean
    showPaymentRequest: () => void
};

export const enum PaymentPlatforms {
    NONE = 'no_payment',
    STRIPE = 'stripe',
    PAYPAL = 'paypal',
    IOS = 'apple',
    ANDROID = 'google',
    AMAZON = 'amazon',
    HALLOW = 'hallow' // subscriptions created by Customer Support on behalf of users
}