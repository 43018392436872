import type { ApiError } from '@models/api';
import { isObject } from './functions';
import { logError } from './loggers';

// determine if object contains an error, and return an error type object
export const isApiError = (obj: any) => {
    const error: ApiError = obj;

    if ((isObject(obj) && (obj.hasOwnProperty('error') || obj.hasOwnProperty('errors') && obj?.errors.length))
        || (isObject(obj) && obj.hasOwnProperty('code') && obj?.code === 'ECONNREFUSED')) return error;
    return false;
};

// determine if we have an error, and throw the error message
export const throwError = (potentialError, intl = null, showErrorAlert = null, setLoading = null, loadingValue = false): boolean => {
    try {
        if (isApiError(potentialError)) throw potentialError;
        else return false;
    } catch (err) {
        if (setLoading) setLoading(loadingValue);
        if (showErrorAlert) showErrorAlert(err?.error || err?.errors[0]?.error || err, intl);
        logError(err);
        return true;
    }
};

// determine which api call returned the error, requires us to set 'callName' property on error object once caught
export const checkErrorOrigin = (err) => {
    let errorOrigin;
    if (err?.error?.callName) errorOrigin = err.error.callName;
    else if (err?.errors?.length && err?.errors[0]?.callName) errorOrigin = err.errors[0]?.callName;
    
    return errorOrigin;
};