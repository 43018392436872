import { apiUpdateUser } from '@utilities/api';
import Button from '@components/Button';
import { COOKIE_POLICY_URL } from '@utilities/urls';
import { logError } from '@utilities/loggers';
import s from './consentPopup.module.scss';
import { saveBrowserConsent } from '@utilities/cookies';
import XButton from '@components/XButton';
import { dispatch, store } from '@store/store';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';

type Props = {
    consentPopupRef: React.MutableRefObject<HTMLDivElement>
}

const ConsentPopup = ({ consentPopupRef }: Props) => {
    const intl = useIntl();
    const [showPopup, setShowPopup] = useState<boolean>(false);

    useEffect(() => {
        const fetchIpDetails = async () => {
            let ipDetails;
            try {
                const ipResponse = await fetch(`https://ipwhois.pro/json/?key=${ process.env.ACCESS_WHOIS_KEY }`);
                ipDetails = await ipResponse.json();
            } catch (err) {
                logError(err);
            }

            // temp line to handle WhatsApp sender limit
            dispatch.session.setIsUsUser(ipDetails?.country_code?.toLowerCase() === 'us' || ipDetails?.country?.toLowerCase() === 'united states');

            const show = shouldSeeCookiePopup(ipDetails);
            setShowPopup(show);
            if (show && consentPopupRef.current) consentPopupRef.current.style.display = 'flex';
        };

        // if consent does not exist
        if (localStorage.consent === undefined) fetchIpDetails();
    }, []);

    useEffect(() => {
        if (showPopup && consentPopupRef.current) consentPopupRef.current.style.display = 'flex';
    }, [consentPopupRef.current]);

    const shouldSeeCookiePopup = (ipDetails) => {
        let needPopup = true;

        // if consent cookie exists, do not show popup
        if (document.cookie.match(/^(.*;)?\s*hlwconsent\s*=\s*[^;]+(.*)?$/)) needPopup = false;
        // if we got ipDetails, check our locale
        else if (ipDetails && ipDetails.success !== false) {
            const { continent_code, continent, country_code, country, region } = ipDetails;

            needPopup = continent_code?.toLowerCase() === 'eu' || continent?.toLowerCase() === 'europe'
                || (country_code?.toLowerCase() === 'us' || country?.toLowerCase() === 'united states') && region?.toLowerCase() === 'california'
                || ['brazil', 'south africa'].includes(country?.toLowerCase());
        }

        dispatch.session.setIsHighPrivacyZone(needPopup);
        return needPopup;
    };

    const closePopup = () => {
        if (!JSON.parse(localStorage.getItem('consent'))) {
            // ensure consent exists, so user does not see popup repeatedly
            saveBrowserConsent(JSON.stringify(null));
        }
        consentPopupRef.current.style.display = 'none';
    };

    const onClickManage = () => {
        window.location.href = `${window.location.href.split('#')[0]}#cookie-manager`;
    };

    const onClickAcceptAll = async () => {
        const consent = [1, 2, 3];
        saveBrowserConsent(JSON.stringify(consent));
        sessionStorage.setItem('hasConsentUpdated', 'true');
        consentPopupRef.current.style.display = 'none';
        if (Object.hasOwn(store.getState().user, 'id')) {
            await apiUpdateUser({ consent });
        }
    };

    return (
        <section ref={consentPopupRef} className={s.consentPopup}>
            <header className={s.consentHeader}>
                <XButton onClick={closePopup} shouldGoBack={false} /> {/* title="Decline" */}
            </header>
            <div className={s.consentContainer}>
                <div className={s.descriptionContainer}>
                    <h4>
                        <FormattedMessage id={'cookie_manager_title'} defaultMessage={'🍪 Cookies'} />
                    </h4>
                    <p>
                        <FormattedMessage
                            id={'cookie_manager_popup_description'}
                            defaultMessage={`We use cookies to enhance your experience, analyze traffic, and for security and marketing. For more info see our ${ <a href={COOKIE_POLICY_URL} target="_blank" rel="noreferrer">{
                                intl.formatMessage({ id: 'cookie_manager_policy_link_uppercase', defaultMessage: 'Cookie Policy' })
                            }</a> }.`}
                            values={{
                                0: <a href={COOKIE_POLICY_URL} target="_blank" className={s.policyLink} rel="noreferrer">{
                                    intl.formatMessage({ id: 'cookie_manager_policy_link_uppercase', defaultMessage: 'Cookie Policy' })
                                }</a>
                            }}
                        />
                    </p>
                </div>
                <div className={s.buttonsContainer}>
                    <Button
                        buttonClass={s.buttonManage}
                        onClick={onClickManage}
                        text={'button_manage'}
                    />
                    <Button
                        buttonClass={s.buttonAcceptAll}
                        onClick={onClickAcceptAll}
                        text={'button_accept_all'}
                    />
                </div>
            </div>
        </section>
    );
};

export default ConsentPopup;