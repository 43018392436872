export enum Keys {
    Enter = 'Enter',
    Space = 'Space',
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    LetterU = 'KeyU',
    LetterD = 'KeyD',
    LetterE = 'KeyE',
    Escape = 'Escape',
    Tab = 'Tab',
    Backspace = 'Backspace'
}