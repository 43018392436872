import React from 'react';
import s from './agreementNotice.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { PRIVACY_URL, TERMS_OF_SERVICE_URL } from '@utilities/urls';

const AgreementNotice = () => {
    const intl = useIntl();
    
    return (
        <footer className={s.agreementNotice}>
            <FormattedMessage
                id={'launch_privacy_and_terms_vars'}
                defaultMessage={'By using Hallow you agree to our Terms and Privacy Policy.'}
                values={{
                    0: <a href={TERMS_OF_SERVICE_URL} target="_blank" rel="noreferrer">
                        {intl.formatMessage({ id: 'settings_terms', defaultMessage: 'Terms of Service' })}
                    </a>,
                    1: <a href={PRIVACY_URL} target="_blank" rel="noreferrer">
                        {intl.formatMessage({ id: 'settings_privacy', defaultMessage: 'Privacy Policy' })}
                    </a>
                }} />
        </footer>
    );
};

export default AgreementNotice;