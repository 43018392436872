// These utilities enable our Vercel builds to work.
// If Access is running in a Vercel environment (pr build),
// then we add the Authorization header to our requests since cookies won't work (cookie domain = hallow.com)

export const getCookieDomain = (host) => {
    const hostArray = host.split(':')[0].split('.');
    const len = hostArray.length;

    if (len === 1) return hostArray[0];
    return `${ hostArray[len - 2] }.${ hostArray[len - 1] }`;
};