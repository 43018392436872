import type { ApiSubscription } from '@models/api';
import Bugsnag from '@bugsnag/js';
import { createModel } from '@rematch/core';
import HallowCookies from '@models/Cookies';
import type { RootModel } from './models';
import type { RootState } from './store';
import { saveBrowserConsent } from '@utilities/cookies';
import { userDefaultConsent } from '@models/store/user';

const updateUserIdentity = (user) => {
    if (Bugsnag.isStarted()) Bugsnag.setUser(user.id as unknown as string, user.email, user.name || '');

    if (window?.analytics) {
        if (user.id === null) window.analytics.reset();
        else {
            window.analytics.identify(user.id as unknown as string, {
                id: user.id,
                name: user?.name || null,
                email: user?.email || null,
                phoneNumber: user?.phone || null,
                locale: user?.language || null,
                consent: user?.consent || null
            });
        }
    }

    // Launch Darkly calls identify via React hook return. FeatureFlaggerProvider watches for user.id change, then identifies
};

export type UserState = {
    alerts: Array<any>;
    anonymousId?: string;
    background_track_id?: number;
    consent: number[],
    country?: string;
    created_at?: string
    debug: boolean;
    email: string;
    family_id?: number;
    goal?: any;
    goals_completed: number;
    groups_count: number;
    guide?: number;
    has_expired_subscription: boolean;
    has_password: boolean;
    id: number;
    is_herald: boolean;
    language: string;
    last_name?: string;
    longest_streak?: number;
    name: string;
    notifications_enabled: boolean;
    oauth: { access_token: string; };
    phone?: string;
    prayer_goals?: any;
    referral_code?: string;
    sessions_count: number;
    sharing_to: Array<any>;
    streak?: number;
    subscription?: ApiSubscription;
    sync_health: boolean;
    sync_journals: boolean;
    time_in_prayer?: any;
    timezone: string;
    isUserUpdating?: boolean // TODO: refactor this store slice or move this to session slice
    isUserIdentifying?: boolean // TODO: refactor this store slice or move this to session slice
}

export const user = createModel<RootModel>()({
    state: { consent: userDefaultConsent, id: null } as UserState,
    reducers: {
        setUser: (state, user) => {
            if (!user) return { id: null };
            return {
                ...state,
                ...user
            };
        },
        setIsUserUpdating: (state, payload) => ({
            ...state,
            isUserUpdating: payload
        }),
        setIsUserIdentifying: (state, payload) => ({
            ...state,
            isUserIdentifying: payload
        })
    },
    effects: (dispatch) => ({
        updateUserState(user) {
            dispatch.user.setUser(user);
            if (user?.language) dispatch.session.setLocale(user.language);
            if (user?.consent) saveBrowserConsent(JSON.stringify(user.consent));

            if (user?.id) updateUserIdentity(user);

            dispatch.user.setIsUserUpdating(false);
        },
        resetUserState() {
            dispatch.user.setUser(null);
            dispatch.session.setLocale('en');
        }
    })
});

export const selectUser = (state: RootState) => state.user;
export const selectHasSubscription = (state: RootState) => !!state.user?.subscription;
export const selectIsUserIdentifying = (state: RootState) => state.user?.isUserIdentifying;
export const selectIsUserUpdating = (state: RootState) => state.user?.isUserUpdating;
export const selectSubExpirationDate = (state: RootState) => new Date(state.user?.subscription?.end_date);
export const selectUserConsent = (state: RootState): Array<Number> => {
    if (state.user?.consent) return state.user.consent;
    else if (typeof document !== undefined && document.cookie.match(/^(.*;)?\s*hlwconsent\s*=\s*[^;]+(.*)?$/)) {
        let consentCookie;
        const dividedCookieArray = `; ${ document.cookie }`.split(`; ${ HallowCookies.CONSENT }=`);
        if (dividedCookieArray.length === 2) consentCookie = JSON.parse(dividedCookieArray.pop()
            .split(';')
            .shift());
        return consentCookie;
    } else if (localStorage.getItem('consent')) return JSON.parse(localStorage.getItem('consent'));
    else if (state.session.isHighPrivacyZone) return [];
    return [1, 2, 3];
};