import React from 'react';

type Props = {
    iconClass?: string
    color?: string
}

const CreditCardIcon = ({ iconClass = '', color = 'black' }: Props) => (
    <svg className={iconClass} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.895431 0 2V4H22V2C22 0.895431 21.1046 0 20 0H2ZM22 8H0V14C0 15.1046 0.89543 16 2 16H20C21.1046 16 22 15.1046 22 14V8Z" fill={color} />
    </svg>
);

export default CreditCardIcon;