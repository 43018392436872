import { clsx } from 'clsx';
import { FormattedMessage } from 'react-intl';
import type { HTMLAttributes } from 'react';
import { Marquee } from '../Marquee';
import styles from './marqueeSubscription.module.scss';

const marqueeSubscriptionImagesDataDesktop = [
    { intlKey: 'onboarding_cherry_subscribe_marquee_1', src: '/images/onboarding/subscription/catholic-lofi.png' },
    { intlKey: 'onboarding_cherry_subscribe_marquee_2', src: '/images/onboarding/subscription/daily-miracles.png' },
    { intlKey: 'onboarding_cherry_subscribe_marquee_3', intlKeyMobile: 'onboarding_cherry_subscribe_marquee_3_mobile', src: '/images/onboarding/subscription/christs-factus-est.png' },
    { intlKey: 'onboarding_cherry_subscribe_marquee_4', intlKeyMobile: 'onboarding_cherry_subscribe_marquee_4_mobile', src: '/images/onboarding/subscription/daily-saint.png' },
    { intlKey: 'onboarding_cherry_subscribe_marquee_5', src: '/images/onboarding/subscription/surrender-novena.png' }
];

export type MarqueeSubscriptionProps = HTMLAttributes<HTMLDivElement>;

export const MarqueeSubscription = ({ className, ...props }: MarqueeSubscriptionProps) => (
    <div className={clsx(styles.displayDesktop, className)} {...props}>
        <Marquee>
            {/* Need multiples of each image for resizing... Marquee has gaps without these! */}
            {[0, 1, 2].map((index) => marqueeSubscriptionImagesDataDesktop.map((marqueeSubscriptionImageData) => (
                <div className={styles.images} key={`${ index }${ marqueeSubscriptionImageData.src }`}>
                    <img alt="" className={styles.image} src={marqueeSubscriptionImageData.src} />
                    {marqueeSubscriptionImageData.intlKeyMobile ? (
                        <>
                            <span className={clsx(styles.span, styles.spanDesktop)}>
                                <FormattedMessage id={marqueeSubscriptionImageData.intlKey} />
                            </span>
                            <span className={clsx(styles.span, styles.spanMobile)}>
                                <FormattedMessage id={marqueeSubscriptionImageData.intlKeyMobile} />
                            </span>
                        </>
                    ) : (
                        <span className={styles.span}>
                            <FormattedMessage id={marqueeSubscriptionImageData.intlKey} />
                        </span>
                    )}
                </div>
            )))}
        </Marquee>
    </div>
);