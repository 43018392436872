import { createContext } from 'react';
import type { PageContextShape } from '@models/PageContext';

export const PageContext = createContext<PageContextShape>(null);

interface Props extends PageContextShape { children: any }

const PageProvider = ({
    children,
    isMobile,
    setFooterTwoButtons,
    setPageCenter,
    setPageFullBackground,
    setPageFullCarousel
}: Props) => (
    <PageContext.Provider value={{
        isMobile,
        setFooterTwoButtons,
        setPageCenter,
        setPageFullBackground,
        setPageFullCarousel
    }}>
        {children}
    </PageContext.Provider>
);

export default PageProvider;