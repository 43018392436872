import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { isArray, keyPlans, MONTHS_PER_YEAR, requestPlans } from '../utilities';
import { useEffect, useState } from 'react';

export type UsePlansProps = {
    defaultPlanExternalId?: string;
}

export const usePlans = ({ defaultPlanExternalId = 'yearly_full_subscription' }: UsePlansProps = {}) => {
    const intl = useIntl();

    const [defaultPlan, setDefaultPlan] = useState<any>({});

    const query = useQuery({
        queryFn: () => requestPlans().then((res) => res.json()),
        queryKey: keyPlans()
    });

    const getDefaultPlanAmount = ({ perTimePeriod }: { perTimePeriod: 'month' | 'year' } = { perTimePeriod: 'year' }) => {
        const amount = defaultPlan?.price?.amount ?? 0;

        if (perTimePeriod === 'month') return amount / MONTHS_PER_YEAR;

        return amount;
    };

    const getDefaultPlanAmountIntl = ({ perTimePeriod }: { perTimePeriod: 'month' | 'year' } = { perTimePeriod: 'year' }) => intl.formatNumber(getDefaultPlanAmount({ perTimePeriod }), { style: 'currency', currency: getDefaultPlanCurrency() });

    const getDefaultPlanCurrency = () => defaultPlan?.price?.currency ?? 'USD';

    useEffect(() => {
        if (query.isSuccess && isArray(query.data)) setDefaultPlan(query.data.filter((plan: any) => plan.external_id === defaultPlanExternalId)[0] ?? {});
    }, [defaultPlanExternalId, query.isSuccess]);

    return {
        defaultPlan,
        getDefaultPlanAmount,
        getDefaultPlanAmountIntl,
        getDefaultPlanCurrency,
        query
    };
};