import React from 'react';

type Props = {
    iconClass?: string
    color?: string
    onClick?: Function
}

const XIcon = ({
    iconClass = '',
    color = 'black',
    onClick = null
}: Props) => (
    <svg className={iconClass} onClick={() => onClick && onClick()} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M20.7062 10.7097C21.0968 10.3192 21.0968 9.68599 20.7063 9.29546C20.3157 8.90494 19.6826 8.90494 19.292 9.29546L14.9994 13.5881L10.7071 9.29585C10.3166 8.90532 9.68342 8.90532 9.29289 9.29585C8.90237 9.68637 8.90237 10.3195 9.29289 10.7101L13.5852 15.0023L9.29289 19.2946C8.90237 19.6851 8.90237 20.3183 9.29289 20.7088C9.68342 21.0993 10.3166 21.0993 10.7071 20.7088L14.9994 16.4165L19.292 20.7092C19.6826 21.0997 20.3157 21.0997 20.7063 20.7092C21.0968 20.3187 21.0968 19.6855 20.7062 19.295L16.4136 15.0023L20.7062 10.7097Z" fill={color}/>
    </svg>
);

export default XIcon;