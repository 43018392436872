import type { SVGAttributes } from 'react';

export type CheckIconProps = SVGAttributes<SVGAElement>;

export const CheckIcon = ({ fill = 'none', height = '8', width = '10' }: CheckIconProps) => (
    <svg
        fill={fill}
        height={height}
        width={width}
        viewBox={`0 0 ${ width } ${ height }`}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 3.5L4 6.5L9 1.5"
            stroke="#6D0EC1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />
    </svg>
);