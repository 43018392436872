export enum HallowCookies {
    AJS_ANONYMOUS_ID = 'ajs_anonymous_id', // anon user id from Segment
    API_ENDPOINT = 'hlwapi',
    CONSENT = 'hlwconsent',
    LD_GUEST_KEY = 'hlwldguestkey',
    REFRESH = 'hlwrfh',
    SESSION = 'hlwsess',
    TIMEZONE = 'hlwtz',
    TOKEN = 'hlwtkn'
}

export default HallowCookies;