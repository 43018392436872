import { DEVICE_TYPES } from '@models/General';
import { v4 as uuidv4 } from 'uuid';

export const uuid = () => {
    return uuidv4();
};

export const shuffle = (array) => {
    let currentIndex = array.length, randomIndex;

    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

export const getDeviceScreen = () => {
    let screenHeight, screenWidth;
    if (typeof screen === 'undefined') {
        screenHeight = window.innerHeight;
        screenWidth = window.innerWidth;
    } else {
        screenHeight = screen.availHeight;
        screenWidth = screen.availWidth;
    }
    
    return {
        formatted: `${ screenWidth }x${ screenHeight }`,
        screenHeight,
        screenWidth
    };
};

export const deviceDetectToDeviceType = (ddType: string): DEVICE_TYPES => {
    if (ddType === 'browser') {
        return DEVICE_TYPES.DESKTOP;
    } else if (ddType === 'mobile') {
        return DEVICE_TYPES.PHONE;
    }
    return ddType as DEVICE_TYPES;
};

export const getDeviceUser = () => window.navigator.userAgent;