import clsx from 'clsx';
import { emailValidation } from '@utilities/validations';
import InputText from '@components/InputText';
import type { PAYPAL_INTENTS } from '@models/PaymentProcessors';
import PaypalProcessor from '@components/PaymentProcessors/PaypalProcessor';
import s from './paypalProcessorDropdown.module.scss';
import { selectUser } from '@store/userModel';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

type Props = {
    additionalContainerClass?: string
    additionalDropdownState: Dispatch<SetStateAction<boolean>>
    disabled?: boolean;
    flow?: string
    intent: PAYPAL_INTENTS
    submitButtonText: string
    total: number
}

const PaypalProcessorDropdown = ({
    additionalContainerClass = null,
    additionalDropdownState,
    disabled,
    flow = null,
    intent,
    total
}: Props) => {
    const intl = useIntl();

    const user = useSelector(selectUser);
    
    const [isCardDropdownOpen, setIsCardDropdownOpen] = useState<boolean>(false);
    const [paymentEmail, setPaymentEmail] = useState<string>('');
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

    useEffect(() => {
        if (user.email) setPaymentEmail(user.email);
    }, [user]);

    useEffect(() => {
        const validity = emailValidation.test(paymentEmail);
        setIsEmailValid(validity);
        if (validity) setShowErrorMessage(false);
    }, [paymentEmail]);

    const setDropdownState = () => {
        if (!user.email) {
            setIsCardDropdownOpen(!isCardDropdownOpen);
            if (additionalDropdownState) additionalDropdownState(!isCardDropdownOpen);
        }
    };

    const onEmailBlur = () => !isEmailValid && setShowErrorMessage(true);

    const onEmailChange = (email) => setPaymentEmail(email);
    
    return (
        <div className={clsx(s.cardDropdownContainer, additionalContainerClass && additionalContainerClass)}>
            <button className={clsx(s.cardDropdownButton, s.paypal)} disabled={disabled} onClick={() => setDropdownState()}>
                <PaypalProcessor containerClass={s.paymentProcessorButton} disabled={disabled} flow={flow} userEmail={user.email ?? isEmailValid ? paymentEmail : null} intent={intent} total={total} />
            </button>
            { isCardDropdownOpen
                && <div className={s.cardDropdownContent}>
                    <InputText
                        autoComplete="email"
                        id="email"
                        label={intl.formatMessage({ id: 'checkout_summary_email_input_label', defaultMessage: 'Email to Receive Receipt' })}
                        name="email"
                        additionalOnBlur={onEmailBlur}
                        onChange={onEmailChange}
                        required={true}
                        type="email"
                        value={paymentEmail}
                    />
                    {showErrorMessage
                        && <p className={s.errorMessage}>
                            <FormattedMessage id={'email_input_invalid_message'} />
                        </p>
                    }
                </div>
            }
        </div>
    );
};

export default PaypalProcessorDropdown;