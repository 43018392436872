import clsx from 'clsx';
import { ICON_DIRECTION } from '@models/General';
import React from 'react';
import s from './arrowIcon.module.scss';

type Props = {
    iconClass?: string
    color?: string
    direction?: ICON_DIRECTION
}

const ArrowIcon = ({ iconClass = '', color = 'black', direction = ICON_DIRECTION.LEFT }: Props) => (
    <svg className={clsx(iconClass && iconClass, s.arrowIcon, direction && s[direction])} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.2 20L9 15M9 15L14.2 10M9 15H22" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default ArrowIcon;