import { isObjectEmpty } from '../../../utilities';
import { store } from '@store/store';
import { useCart } from '../../../hooks';
import { ReactNode, useEffect } from 'react';

const { dispatch } = store;

export type SyncReactQueryWithReduxWrapperProps = {
    children: ReactNode;
}
export const SyncReactQueryWithReduxWrapper = ({ children }: SyncReactQueryWithReduxWrapperProps) => {
    const { cart, getCartPromoCode } = useCart();

    const syncReactQueryWithRedux = () => {
        if (!isObjectEmpty(cart)) dispatch.order.updateOrderState(cart);
        if (getCartPromoCode()) dispatch.order.updateCodeState(getCartPromoCode(), true);
    };

    useEffect(() => {
        syncReactQueryWithRedux();
    }, [cart]);

    return children as JSX.Element;
};