import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import type { ReactElement } from 'react';
import s from './inputText.module.scss';
import React, { useState } from 'react';
 
type Props = {
    additionalClass?: string
    additionalOnBlur?: () => void
    disabled?: boolean
    id: string
    label?: string
    name: string
    onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
    required?: boolean
    type?: string
    autoComplete: string
    value: string
}

const InputText = ({
    additionalClass = null,
    additionalOnBlur = null,
    disabled = false,
    id,
    label,
    name,
    onChange,
    required = false,
    type = 'text',
    autoComplete,
    value
}: Props): ReactElement => {
    const [focus, setFocus] = useState<boolean>(false);

    const handleOnChange = (value) => {
        onChange(value);
    };

    const onBlur = () => {
        setFocus(false);
        if (additionalOnBlur) additionalOnBlur();
    };
    
    return (
        <label className={clsx(s.inputLabel, additionalClass && additionalClass)} htmlFor={id || name}>
            <div className={clsx(s.inputPlaceholder, (focus || value?.length) && s.inputPlaceholderPopulated)}>
                {label && <FormattedMessage id={label} defaultMessage={label} />}
            </div>

            <input
                className={s.input}
                disabled={disabled}
                type={type}
                autoComplete={autoComplete}
                onBlur={() => onBlur()}
                onChange={(e) => handleOnChange(e.target.value)}
                onFocus={() => setFocus(true)}
                required={required}
                value={value}
            />
        </label>
    );
};

export default InputText;