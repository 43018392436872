import { apiAppleAuth } from '@utilities/api';
import AppleSignin from 'react-apple-signin-auth';
import Button from '@components/Button/Button';
import { BUTTON_TYPE } from '@models/components';
import { showErrorMessage } from '@utilities/loggers';
import { SSO_BUTTON } from '@models/Auth';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { v4 as uuid } from 'uuid';
import { checkErrorOrigin, throwError } from '@utilities/errors';
import type { Dispatch, SetStateAction } from 'react';
import { loginRedirect, onSSOFailure } from '@utilities/functions';

type Props = {
    additionalOnClick?: Function
    buttonLoading: SSO_BUTTON
    setButtonLoading: Dispatch<SetStateAction<SSO_BUTTON>>
}

const ButtonApple = ({
    additionalOnClick = null,
    buttonLoading,
    setButtonLoading,
}: Props) => {
    const router = useRouter();
    const intl = useIntl();

    const chooseApple = async (res) => {
        if (res?.error) {
            showErrorMessage(res.error, intl, null, 'login_social_auth_error_message');
            onSSOFailure(res?.error, intl, setButtonLoading);
        } else {
            const token = res.authorization.code;
            const name = res?.user?.name?.firstName ?? null;
            const last_name = res?.user?.name?.lastName ?? null;

            const user = await apiAppleAuth(token, name, last_name);
            if (throwError(user, intl, showErrorMessage, setButtonLoading, null) && checkErrorOrigin(user) !== 'apiPostCart') return;

            setButtonLoading(null);
            loginRedirect(router, intl, user, user.is_new);
        }
    };

    const onAppleClick = (f) => {
        setButtonLoading(SSO_BUTTON.APPLE);
        if (additionalOnClick) additionalOnClick();
        f();
    };

    return (
        <AppleSignin
            authOptions={{
                clientId: process.env.ACCESS_APPLE_CLIENT_ID,
                scope: process.env.ACCESS_APPLE_SCOPE,
                redirectURI: process.env.ACCESS_APPLE_REDIRECT_URI,
                state: uuid(),
                usePopup: true
            }}
            onSuccess={(res) => chooseApple(res)}
            onError={(err) => chooseApple(err)}
            render={(props) => <Button
                {...props}
                buttonIconSrc={'/logo_apple_black.svg'}
                buttonType={BUTTON_TYPE.APPLE}
                disabled={!!buttonLoading}
                loading={buttonLoading === SSO_BUTTON.APPLE}
                text={'launch_continue_with_apple'}
                onClick={() => onAppleClick(props.onClick)} />
            }
        />
    );
};

export default ButtonApple;