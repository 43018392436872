import { clsx } from 'clsx';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { OnboardingContext } from '@providers/OnboardingProvider/OnboardingContext';
import styles from './onboardingSubscriptionDesignYourTrialTemplate.module.scss';
import stylesCartTable from '../../styles/cartTable.module.scss';
import stylesOnboardingSubscription from '../../styles/onboardingSubscription.module.scss';
import { Button, CartPayments, CartTableTotalMonthly, MarqueeSubscription, RadioGroup, RadioItemPromoCode, Separator, StripeProvider } from '../../components';
import { DESIGN_YOUR_TRIAL_399_90_DAYS, DESIGN_YOUR_TRIAL_FREE_30_DAYS, isObjectEmpty } from '../../utilities';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useCart, usePlans, usePromoCode } from '../../hooks';

const designYourTrialPromoCodes = [DESIGN_YOUR_TRIAL_FREE_30_DAYS, DESIGN_YOUR_TRIAL_399_90_DAYS];

export type OnboardingSubscriptionDesignYourTrialTemplateProps = {
    templateName: string
    templateRef: React.MutableRefObject<HTMLDivElement>
};

export const OnboardingSubscriptionDesignYourTrialTemplate = ({ templateName, templateRef }: OnboardingSubscriptionDesignYourTrialTemplateProps) => {
    const [currentPromoCode, setCurrentPromoCode] = useState(designYourTrialPromoCodes[0]);
    const [isRedeemButtonClicked, setIsRedeemButtonClicked] = useState(false);

    const checkoutContainerRef = useRef(null);

    const { cart, getCartTotalIntl, mutationPost: mutationPostCart } = useCart();
    const { defaultPlan, getDefaultPlanAmountIntl } = usePlans();
    const { getPromoCodeAmountIntl, getPromoCodeTrialLengthIntl } = usePromoCode({ promoCode: currentPromoCode });

    const { priceStrikethroughVariant } = useContext(OnboardingContext);

    useEffect(() => {
        if (!isObjectEmpty(defaultPlan)) mutationPostCart.mutate({ requestInit: { body: JSON.stringify({ price_id: defaultPlan?.price?.id, promo_code: currentPromoCode }) } });
    }, [currentPromoCode, defaultPlan]);

    useEffect(() => {
        if (isRedeemButtonClicked) setTimeout(() => checkoutContainerRef.current?.scrollIntoView({ behavior: 'smooth' }), 100);
    }, [isRedeemButtonClicked]);

    const showCheckout = !isObjectEmpty(cart) && isRedeemButtonClicked;

    const showStrikethroughPrice = (total) => {
        return total === '$69.99'
            ? <><span className={styles.strikethrough}>{'$119.99'}</span> {total}</>
            : total;
    };
   
    return (
        <section className={stylesOnboardingSubscription.section} data-template={templateName} ref={templateRef}>
            <MarqueeSubscription />
            <div className={styles.container}>
                <div className={clsx(styles.stack, styles.textStack, styles.textStackMd)}>
                    <h1 className={stylesOnboardingSubscription.h1}>
                        <FormattedMessage id="general_words_design_your_trial" />
                    </h1>
                    <p className={stylesOnboardingSubscription.p}>
                        <FormattedMessage id="meditate_with_god_10000" />
                    </p>
                </div>
                <RadioGroup onValueChange={(value) => setCurrentPromoCode(value)} value={currentPromoCode}>
                    {designYourTrialPromoCodes.map((designYourTrialPromoCode, index) => (
                        <Fragment key={designYourTrialPromoCode}>
                            {index === 0 && <Separator />}
                            <RadioItemPromoCode promoCode={designYourTrialPromoCode} value={designYourTrialPromoCode} />
                            <Separator />
                        </Fragment>
                    ))}
                </RadioGroup>
                {showCheckout && (
                    <div
                        ref={checkoutContainerRef} className={clsx(styles.checkout, styles.stack)}>
                        <div className={clsx(styles.stack, styles.textStack, styles.textStackXs)}>
                            <h2 className={stylesOnboardingSubscription.h2}>
                                <FormattedMessage id="redeem_your_price_time_trial" values={{ 0: getPromoCodeAmountIntl(), 1: getPromoCodeTrialLengthIntl({ daysPerMonthMultiplierThreshold: 1 }) }} />
                            </h2>
                            <p className={stylesOnboardingSubscription.p}>
                                <FormattedMessage id="general_words_no_commitment" />.&nbsp;
                                <FormattedMessage id="general_words_cancel_anytime" />.
                            </p>
                        </div>
                        <Separator />
                        <div className={clsx(styles.stack, styles.textStackSm)}>
                            <CartTableTotalMonthly />
                            <span className={stylesCartTable.td}>
                                *<FormattedMessage id="dynamic_words_billed_annually_after_trial" values={{ 0: priceStrikethroughVariant === 'Treatment' ? showStrikethroughPrice(getCartTotalIntl()) : getCartTotalIntl() }} />
                            </span>
                        </div>
                        <Separator />
                        <StripeProvider>
                            <CartPayments />
                        </StripeProvider>
                        <span className={stylesOnboardingSubscription.span}>
                            <FormattedMessage id="general_sentences_you_wont_be_charged___" />
                        </span>
                    </div>
                )}
                <div className={clsx(styles.stack, styles.textStack, styles.textStackLg)}>
                    {!showCheckout && (
                        <span className={stylesOnboardingSubscription.span}>
                            <FormattedMessage id="general_words_cancel_anytime" />
                        </span>
                    )}
                    <Link className={stylesOnboardingSubscription.a} href="/redeem/code">
                        <FormattedMessage id="general_words_have_a_promo_code" />
                    </Link>
                    {!isRedeemButtonClicked && (
                        <>
                            <Button onClick={() => setIsRedeemButtonClicked(!isRedeemButtonClicked)} type="button">
                                <FormattedMessage id="dynamic_words_redeem_for" values={{ 0: getPromoCodeTrialLengthIntl(), 1: getPromoCodeAmountIntl() }} />
                            </Button>
                            <p className={clsx(stylesOnboardingSubscription.p, styles.marginTop)}>
                                <FormattedMessage id="trial_for_cost_then_plan_cost" values={{ 0: getPromoCodeTrialLengthIntl({ daysPerMonthMultiplierThreshold: 1 }), 1: getPromoCodeAmountIntl({ override0WithFree: true }).toLowerCase(), 2: priceStrikethroughVariant === 'Treatment' ? showStrikethroughPrice(getDefaultPlanAmountIntl()) : getDefaultPlanAmountIntl(), 3: getDefaultPlanAmountIntl({ perTimePeriod: 'month' }) }} />
                            </p>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};