import React from 'react';
import s from './xButton.module.scss';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

type Props = {
    onClick?: () => void
    shouldGoBack?: boolean
}

const XButton = ({ onClick, shouldGoBack = true }: Props) => {
    const intl = useIntl();
    const router = useRouter();
    
    const handleClick = () => {
        if (onClick) onClick();
        if (shouldGoBack) router.back();
    };

    return (
        <span onClick={() => handleClick()} className={s.xButton}>
            <img
                src={'/x_black.svg'}
                alt={intl.formatMessage({ id: 'x_button_alt_description', defaultMessage: 'X Button Icon' })}
                className={s.xButtonIcon}
            />
        </span>
    );
};

export default XButton;