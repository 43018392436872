export const MOBILE_MAX_WIDTH = 768;
export const DESKTOP_FOOTER_HEIGHT = 64;

export const MS_PER_SEC = 1000;
export const SEC_PER_MIN = 60;
export const MIN_PER_HOUR = 60;
export const HOUR_PER_DAY = 24;

export const DAYS_PER_MONTH = 30;
export const DAYS_PER_YEAR = 365;
export const DAYS_PER_LIFETIME = 1825;

export const ONE_HUNDRED = 100;
export const ONE_PERCENT = 0.01;
export const MONTHS_PER_YEAR = 12;

export const TWO_WEEKS_IN_DAYS = 14;