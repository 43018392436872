import Swal from 'sweetalert2';

export const logDebug = (...messages) => {
    if (process.env.NODE_ENV === 'development') {
        const dt = new Date;
        console.log('[[', dt.toLocaleDateString(), '@', dt.toLocaleTimeString(), ']]', ...messages);
    }
};

export const logWarn = (...errs) => {
    if (process.env.NODE_ENV === 'development') {
        const dt = new Date;
        console.warn('[[', dt.toLocaleDateString(), '@', dt.toLocaleTimeString(), ']]', ...errs);
    }
};

export const logError = (...errs) => {
    if (process.env.NODE_ENV === 'development') {
        const dt = new Date;
        console.error('[[', dt.toLocaleDateString(), '@', dt.toLocaleTimeString(), ']]', ...errs);
    }
};

export const showErrorMessage = (errorMessage, intl, setLoading = null, overrideErrorMessage = null) => {
    if (setLoading) setLoading(null);
    
    Swal.fire({
        titleText: intl.formatMessage({ id: 'general_word_error', defaultMessage: 'Error' }),
        text: intl.formatMessage({ id: overrideErrorMessage || errorMessage, defaultMessage: overrideErrorMessage || errorMessage }),
        icon: 'error',
        confirmButtonText: intl.formatMessage({ id: 'general_word_ok', defaultMessage: 'Ok' }),
        buttonsStyling: false,
        customClass: { confirmButton: 'hallowButton alertButton' }
    });
};