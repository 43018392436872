import Authentication from 'layouts/authentication';
import type { GetServerSideProps } from 'next';
import requireGuest from '@utilities/HOC/requireGuest';

const Index = () => <Authentication />;

export default Index;

export const getServerSideProps: GetServerSideProps = requireGuest(
    async (ctx) => ({ props: {} })
);