import AgreementNotice from '@components/AgreementNotice';
import { AuthFlow } from '@v2/@packages/ui';
import { Button } from '@v2';
import ButtonApple from '@components/ButtonApple';
import ButtonGoogle from '@components/ButtonGoogle';
import clsx from 'clsx';
import { LogoTextImg } from '@hallow-inc/ui';
import s from './authenticationMethods.module.scss';
import { SSO_BUTTON } from '@models/Auth';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';
import { selectCampaignName, selectReferralName } from '@store/sessionModel';

type Props = {
    ctaText?: string
    onboardingOnAuthClick?: Function
    variant?: AuthFlow
}

const AuthenticationMethods = ({
    ctaText = null,
    onboardingOnAuthClick = null,
    variant = 'default'
}: Props) => {
    const intl = useIntl();
    const router = useRouter();
    const campaignName = useSelector(selectCampaignName);
    const referralName = useSelector(selectReferralName);
    
    const [buttonLoading, setButtonLoading] = useState<SSO_BUTTON>(null);
    
    const onAuthClick = () => {
        if (onboardingOnAuthClick) onboardingOnAuthClick();
        else if (variant === 'campaigns') router.push('/auth?flow=campaigns');
        else router.push('/auth');
    };

    return (
        <div className={s.authenticationMethods}>
            {variant === 'referral'
                ? <h3 className={s.referralTitle}>
                        <FormattedMessage
                            id="referral_landing_title"
                            defaultMessage={`${ referralName } thinks you’d enjoy Hallow!`}
                            values={ { 0: !!referralName ? referralName : intl.formatMessage({ id: 'sentence_prefix_your_friend', defaultMessage: 'Your Friend' }) } }
                        />
                    </h3>
                :
                    <LogoTextImg
                        alt={intl.formatMessage({ id: 'app_name', defaultMessage: 'Hallow' })}
                        className={s.logo}
                    />
            }
            <p className={clsx(s.description, variant === 'referral' && s.tightSpacing)}>
                {variant === 'campaigns'
                    ? <FormattedMessage
                            id="campaign_landing_title"
                            values={ { 0: !!campaignName ? campaignName : intl.formatMessage({ id: 'sentence_suffix_prayer_campaign', defaultMessage: 'a prayer campaign' }) } }
                        />
                    : <FormattedMessage id={ctaText ?? 'choose_log_in_method'} defaultMessage={'Choose your log in method below'} />
                }
            </p>
            <div className={s.buttonContainer}>
                <Button onClick={() => onAuthClick()}>
                    <FormattedMessage id="sms_sign_up_continue_phone_or_email" />
                </Button>
                <ButtonGoogle
                    buttonLoading={buttonLoading}
                    setButtonLoading={setButtonLoading} />
                <ButtonApple
                    buttonLoading={buttonLoading}
                    setButtonLoading={setButtonLoading} />
            </div>
            <AgreementNotice />
        </div>
    );
};

export default AuthenticationMethods;