import { Button } from '../Button';
import React from 'react';
import styles from './contentSelectorButton.module.scss';
import { HallowElement, HallowElementProps } from '@v2/@packages/ui';
import Icon, { IconProps } from '@v2/components/icons/Icon/Icon';

const Element: HallowElement = 'button';

type ContentSelectorButtonProps = Omit<HallowElementProps<typeof Element>, 'type'> & {
    icon: IconProps['icon']
    iconFill: string
    size: number
    type?: IconProps['type']
}

const ContentSelectorButton = ({ icon, iconFill, size, type = 'default', ...props }: ContentSelectorButtonProps) => {
    return (
        <Button className={styles.contentSelectorButton} {...props}>
            <Icon
                {...{ icon, type } as IconProps}
                fill={iconFill}
                size={size}
            />
        </Button>
    );
};

export default ContentSelectorButton;