import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import type { ProviderProps } from './types';

const stripe = loadStripe(process.env.ACCESS_STRIPE_KEY as string);

export type StripeProviderProps = ProviderProps;

export const StripeProvider = ({ children }: StripeProviderProps) => {
    return (
        <Elements stripe={stripe}>
            {children}
        </Elements>
    );
};