import { clsx } from 'clsx';
import { FormattedMessage } from 'react-intl';
import { HTMLAttributes } from 'react';

import styles from './onboardingNav.module.scss';

export type OnboardingNavProps = HTMLAttributes<HTMLDivElement>;

export const OnboardingNav = ({ className, ...props }: OnboardingNavProps) => (
    <nav className={clsx(styles.nav, className)} {...props}>
        <div className={styles.div}>
            <span className={styles.span}>
                <FormattedMessage id="onboarding_web_original_header_welcome_to_hallow" />
            </span>
        </div>
    </nav>
);