/* eslint-disable no-magic-numbers */

import { clsx } from 'clsx';
import { MONTHS_PER_YEAR } from '../../utilities';
import stylesCartTable from '../../styles/cartTable.module.scss';
import { useCart } from '../../hooks';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import type { HTMLAttributes, ReactNode } from 'react';

export type CartTableTotalMonthlyProps = HTMLAttributes<HTMLTableElement> & {
    tds?: [ReactNode, ReactNode, ReactNode, ReactNode];
};

export const CartTableTotalMonthly = ({ tds, ...props }: CartTableTotalMonthlyProps) => {
    const { getCartAmountIntl, getCartCurrency, getCartTotal, getCartTax } = useCart();
    return (
        <table className={stylesCartTable.table} {...props}>
            <tbody className={stylesCartTable.tbody}>
                {getCartTax() > 0 ? <tr className={stylesCartTable.tr}>
                    <td className={clsx(stylesCartTable.td, stylesCartTable.tdCapitalize)}>
                        <FormattedMessage id={'web_line_item_tax'} />
                    </td>
                    <td className={stylesCartTable.td}>
                        <FormattedNumber currency={getCartCurrency()} style={'currency'} value={getCartTax()} />
                    </td>
                </tr> : null}
                <tr className={stylesCartTable.tr}>
                    <td className={clsx(stylesCartTable.tdBold, stylesCartTable.tdCapitalize)}>
                        <FormattedMessage id="general_words_total_due_today" />
                    </td>
                    <td className={stylesCartTable.tdBold}>
                        {getCartAmountIntl()}
                    </td>
                </tr>
                <tr className={stylesCartTable.tr}>
                    <td className={clsx(stylesCartTable.td, stylesCartTable.tdCapitalize)}>
                        <FormattedMessage id="general_words_cost_per_month" />*
                    </td>
                    <td className={stylesCartTable.td}>
                        <FormattedNumber currency={getCartCurrency()} style="currency" value={getCartTotal() / MONTHS_PER_YEAR } />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};