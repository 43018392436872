import { createContext, Dispatch } from 'react';

export type ContextType = {
    cart: any;
    setCart: Dispatch<any>;
}

const defaultContext: ContextType = {
    cart: {},
    setCart: () => {}
};

export const Context = createContext<ContextType>(defaultContext);