import { ChangeEvent, HTMLAttributes } from 'react';
import styles from './addressForm.module.scss';
import { useSelector } from 'react-redux';
import { selectAddress } from '@store/orderModel';
import { store } from '@store/store';

const { dispatch } = store;

export type AddressFormProps = HTMLAttributes<HTMLFormElement>;

export const AddressForm = ({ ...props }) => {
    const address = useSelector(selectAddress);

    const handleAddressKeyChange = (key: string, value: any) => {
        dispatch.order.setAddress({ [key]: value || null });
    };

    const handleCPFChange = (event: ChangeEvent<HTMLInputElement>) => {
        const eventTargetValue = event.target.value;

        if (eventTargetValue.length > 11 || !/^[0-9]*$/.test(eventTargetValue)) return;
        handleAddressKeyChange('metadata', { ...address?.metadata ?? {}, cpf: event.target.value });
    };

    const handlePostalCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const eventTargetValue = event.target.value;

        if (eventTargetValue.length > 9 || !/^[0-9-]*$/.test(eventTargetValue)) return;
        if (!eventTargetValue) return handleAddressKeyChange('postalCode', eventTargetValue);

        let newPostalCode = eventTargetValue;

        if (eventTargetValue.length === 6) {
            newPostalCode = `${newPostalCode.slice(0, -1)}-${newPostalCode.charAt(newPostalCode.length - 1)}`;
        }

        if (eventTargetValue.charAt(eventTargetValue.length - 1) === '-') newPostalCode = eventTargetValue.slice(0, -1);

        return handleAddressKeyChange('postalCode', newPostalCode);
    };

    return (
        <form className={styles.form} {...props}>
            <input
                className={styles.input}
                onChange={(event) => handleAddressKeyChange('metadata', { ...address?.metadata ?? {}, name: event.target.value })}
                placeholder="Nome Completo"
                value={address?.metadata ? address?.metadata?.name || '' : ''}
                type="text"
            />
            <input
                className={styles.input}
                onChange={handleCPFChange}
                placeholder="CPF"
                value={address?.metadata ? address?.metadata?.cpf || '' : ''}
            />
            <input
                className={styles.input}
                onChange={(event) => handleAddressKeyChange('line1', event.target.value)}
                placeholder="Endereço"
                value={address?.line1 || ''}
                type="text"
            />
            <input
                className={styles.input}
                onChange={(event) => handleAddressKeyChange('line2', event.target.value)}
                placeholder="Bairro"
                value={address?.line2 || ''}
            />
            <div className={styles.flex}>
                <input
                    className={styles.input}
                    onChange={(event) => handleAddressKeyChange('city', event.target.value)}
                    placeholder="Cidade"
                    value={address?.city || ''}
                    type="text"
                />
                <input
                    className={styles.input}
                    onChange={(event) => handleAddressKeyChange('state', event.target.value)}
                    placeholder="Estado"
                    value={address?.state || ''}
                    type="text"
                />
            </div>
            <input
                className={styles.input}
                onChange={handlePostalCodeChange}
                placeholder="CEP"
                value={address?.postalCode || ''}
            />
        </form>
    );
};