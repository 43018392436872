export enum SCREEN_NAMES {
    '/auth' = 'auth_main',
    '/onboarding' = 'onboarding',
    '/onboarding/jonathan-roumie' = 'onboarding_jonathan_roumie',
    '/onboarding/mark-wahlberg' = 'onboarding_mark_wahlberg',
    '/onboarding/sb' = 'onboarding_super_bowl_landing',
    '/onboarding/sb/preview' = 'onboarding_super_bowl_preview',
    '/onboarding/sb/subscribe' = 'onboarding_super_bowl_subscribe',
    '/onboarding/subscription' = 'payment_form',
    '/products/checkout' = 'billing_summary',
    '/redeem' = 'redeem_landing',
    '/redeem/congrats' = 'redeem_congrats',
    '/redeem/ineligible' = 'redeem_not_eligible',
    '/redeem/ineligible/active-subscription' = 'redeem_not_applicable',
}