import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { DAYS_PER_MONTH, keyPromoCode, requestPromoCode } from '../utilities';

export type UsePromoCodeProps = {
    promoCode: string;
}

export const usePromoCode = ({ promoCode }: UsePromoCodeProps) => {
    const intl = useIntl();

    const query = useQuery({
        enabled: promoCode !== undefined,
        queryFn: () => requestPromoCode({ promoCode }).then((res) => res.json()),
        queryKey: keyPromoCode({ promoCode })
    });

    const getPromoCodeAmount = () => getPromoCodeGrant('one_time_payment_grant')?.variables.amount ?? 0;

    const getPromoCodeAmountIntl = ({ override0WithFree = false }: { override0WithFree?: boolean } = {}) => {
        const promoCodeAmount = getPromoCodeAmount();
        
        if (override0WithFree && promoCodeAmount === 0) return intl.formatMessage({ id: 'general_word_free' });

        return intl.formatNumber(promoCodeAmount, { style: 'currency', currency: getPromoCodeCurrency() });
    };

    const getPromoCodeCurrency = () => getPromoCodeGrant('one_time_payment_grant')?.variables.currency ?? 'USD';

    const getPromoCodeGrant = (grantName: string) => getPromoCodeGrants().find((grant) => grant.name === grantName);

    const getPromoCodeGrants = () => query.data?.grants as any[] ?? [];

    const getPromoCodeTrialLength = () => getPromoCodeGrant('trial_days_grant')?.variables.trial_days ?? 0;

    const getPromoCodeTrialLengthIntl = ({ daysPerMonthMultiplierThreshold = 2 }: { daysPerMonthMultiplierThreshold?: number } = {}) => {
        const promoCodeTrialLength = getPromoCodeTrialLength();

        if (promoCodeTrialLength >= DAYS_PER_MONTH * daysPerMonthMultiplierThreshold && promoCodeTrialLength % DAYS_PER_MONTH === 0) return intl.formatMessage({ id: 'plural_months' }, { plural_months: Math.round(promoCodeTrialLength / DAYS_PER_MONTH) });

        return intl.formatMessage({ id: 'plural_days' }, { plural_days: promoCodeTrialLength });
    };

    return {
        getPromoCodeAmount,
        getPromoCodeAmountIntl,
        getPromoCodeCurrency,
        getPromoCodeGrant,
        getPromoCodeGrants,
        getPromoCodeTrialLength,
        getPromoCodeTrialLengthIntl,
        query
    };
};