import clsx from 'clsx';
import { isApiError } from '@utilities/errors';
import PaypalLogoIcon from '@components/icons/PaypalLogoIcon';
import s from './paypalProcessor.module.scss';
import { selectLocale } from '@store/sessionModel';
import { selectPlanPriceCurrency } from '@store/orderModel';
import { showErrorMessage } from '@utilities/loggers';
import { useAnalytics } from '@utilities/hooks/useAnalytics';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { client, paypalCheckout } from 'braintree-web';
import { handleSubscriptionSuccess, processSubscription } from '@utilities/subscriptions';
import { PaymentPlatforms, PAYPAL_INTENTS } from '@models/PaymentProcessors';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { OnboardingContext } from '@providers/OnboardingProvider/OnboardingContext';

type Props = {
    containerClass: string
    disabled?: boolean;
    flow?: string
    userEmail: string
    intent: PAYPAL_INTENTS
    total: number
}

const PaypalProcessor = ({
    containerClass,
    disabled,
    flow = null,
    userEmail,
    intent,
    total
}: Props) => {
    const analytics = useAnalytics();
    const intl = useIntl();
    const router = useRouter();
    const onboarding = useContext(OnboardingContext);

    const planCurrency = useSelector(selectPlanPriceCurrency);

    const locale = useSelector(selectLocale);
    const [paypalInstance, setPaypalInstance] = useState<any>(null);
    const paypalButtonContainerRef = useRef<HTMLDivElement>(null);

    const [iframeHasFocus, setIframeHasFocus] = useState<boolean>(false);

    /*
        Setup Braintree (for Paypal)
    */
    useEffect(() => {
        const setupBraintree = async () => {
            const clientInstance = await client.create({ authorization: process.env.ACCESS_BRAINTREE_TOKEN });
            setupPaypal(clientInstance);
        };
        setupBraintree();

        // listen to 'blur' to see if we left window (to focus iframe)
        // listen to 'focus' to see current activeElement (check if iframe)
        window.addEventListener('blur', checkFocus);
        window.addEventListener('focus', checkFocus);
    }, []);

    useEffect(() => {
        if (paypalInstance && userEmail) createButtonConfig();
    }, [
        paypalInstance,
        userEmail,
        total,
        locale
    ]);

    const checkFocus = () => {
        // assumes there is only one iframe on page
        if (document.activeElement === document.querySelector('iframe')) setIframeHasFocus(true);
        else setIframeHasFocus(false);
    };

    const createPaypalConfig = () => ({
        currency: planCurrency,
        // if intent is for subscription, set intent to null/empty the default intent for the vault flow is null/empty, NOT 'subscription' or anything else
        intent: intent === PAYPAL_INTENTS.GIFT ? intent : '',
        vault: intent === PAYPAL_INTENTS.SUBSCRIPTION
    });

    const setupPaypal = async (clientInstance) => {
        const newPaypalInstance = await paypalCheckout.create({ client: clientInstance });

        // This line generates our global 'paypal' object
        await newPaypalInstance.loadPayPalSDK(createPaypalConfig());

        setPaypalInstance(newPaypalInstance);
    };

    const createButtonConfig = () => {
        paypalButtonContainerRef?.current?.replaceChildren();
        
        const buttonConfig = {
            // @ts-ignore
            fundingSource: paypal.FUNDING.PAYPAL,
            style: {
                color: 'white',
                shape: 'rect',
                size: 'tiny',
                layout: 'vertical',
                height: 55
            },
            onApprove: (data) => onApprove(data, paypalInstance, userEmail),
            onError: (err) => onError(err)
        };

        if (intent === PAYPAL_INTENTS.SUBSCRIPTION) {
            // subscriptions create a 1) 'Billing Agreement' with 2) 'Vault Flow'
            // @ts-ignore
            buttonConfig.createBillingAgreement = () => {
                return paypalInstance.createPayment({
                    flow: 'vault',
                    amount: total,
                    currency: planCurrency,
                    enableShippingAddress: true,
                    locale: locale
                });
            };
        } else {
            // gift cards create an 1) 'Order' with 2) 'Checkout Flow'
            // @ts-ignore
            buttonConfig.createOrder = () => {
                return paypalInstance.createPayment({
                    flow: 'checkout',
                    amount: total,
                    currency: planCurrency,
                    enableShippingAddress: false,
                    locale: locale
                });
            };
        }

        // @ts-ignore
        paypal.Buttons(buttonConfig).render(paypalButtonContainerRef?.current);
    };

    const onApprove = useCallback(async (data, paypalInstance, email) => {
        try {
            const paymentToken = await paypalInstance.tokenizePayment(data);

            const subscription = await processSubscription(PaymentPlatforms.PAYPAL, paymentToken, email);
            if (isApiError(subscription)) throw subscription;

            handleSubscriptionSuccess(subscription, router, analytics, onboarding);
        } catch (err) {
            showErrorMessage(err.message ?? err.error ?? err, intl);
        }
    }, [onboarding]);

    const onError = (err) => {
        showErrorMessage(err?.message ?? err?.error ?? err, intl);
    };

    return (
        <div className={clsx(s.paypalProcessorContainer, containerClass)}>
            {/* actual PayPal button */}
            {!disabled && <div id="paypal-button" className={clsx(s.paypalButton, !userEmail && s.missingUserEmail)} ref={paypalButtonContainerRef} role={'button'} />}
            {/* overlay / visible UI for PayPal button */}
            <div className={clsx(s.hallowPaypalButton, !disabled && iframeHasFocus && s.hasFocus)}>
                <PaypalLogoIcon iconClass={s.paypalLogo} />
            </div>
        </div>
    );
};

export default PaypalProcessor;