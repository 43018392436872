import { buildLDUserContext } from '@utilities/featureFlagger';
import { dispatch } from '@store/store';
import { FeatureFlaggerContext } from './FeatureFlaggerContext';
import { selectUser } from '@store/userModel';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { usePromoCode } from '@v2';

const BRAZIL_REDEEMABLE_ERROR_CODE = 3021;

type AuthorizedUserLDUserContext = {
    kind: string
    key: string
    anonymous: boolean
    name: string
    apiVersion: string
    appVersion: string
    browser: string
    deviceType: string
    platform: string
    preferredLocales: string[]
    referrer: string
    screenHeight: number
    screenWidth: number
    utmCampaign: string
    utmMedium: string
    utmSource: string
}

type AnonymousUserLDGuestContext = {
    kind: string
    key: string
    anonymous: boolean
    name: string
    apiVersion: string
    appVersion: string
    browser: string
    deviceType: string
    platform: string
    preferredLocales: string[]
    referrer: string
    screenHeight: number
    screenWidth: number
    utmCampaign: string
    utmMedium: string
    utmSource: string
}

type AuthorizedUserLDMultiContext = {
    kind: string
    user: AuthorizedUserLDUserContext
    guest: AnonymousUserLDGuestContext
}

export enum LD_CONTEXT_KINDS {
    GUEST = 'guest',
    MULTI = 'multi',
    USER = 'user',
}

interface Props { children: any }

const FeatureFlaggerProvider = ({ children }: Props) => {
    const ldClient = useLDClient();

    const user = useSelector(selectUser);

    const [ldFlags, setLdFlags] = useState<any>({});

    const { query: queryBrazilRosaryAB2Yr } = usePromoCode({ promoCode: 'BrazilRosaryAB2Yr' });
    const isBrazilRosaryAB2YrRedeemable = queryBrazilRosaryAB2Yr.data?.code !== BRAZIL_REDEEMABLE_ERROR_CODE;

    useEffect(() => { identify(); }, [isBrazilRosaryAB2YrRedeemable, ldClient, user?.id]);

    const identify = async () => {
        if (!ldClient) return;

        try {
            await ldClient.waitForInitialization();

            // get and create contexts
            const currentContext: any = ldClient.getContext();
            let newContext: {}|AuthorizedUserLDMultiContext|AnonymousUserLDGuestContext = {};

            if (user?.id) {
                (newContext as AuthorizedUserLDMultiContext).kind = LD_CONTEXT_KINDS.MULTI;
                (newContext as AuthorizedUserLDMultiContext).user = buildLDUserContext(user, { WebBrazilRosary2Yr: isBrazilRosaryAB2YrRedeemable });
                // if guest does not exist, then the whole context is the guest object
                (newContext as AuthorizedUserLDMultiContext).guest = currentContext?.guest ?? currentContext;
            } else {
                newContext = buildLDUserContext(null, { WebBrazilRosary2Yr: isBrazilRosaryAB2YrRedeemable });
            }

            // send updated context to LD
            await ldClient.identify(newContext, null, (err, flags) => {
                // save updated flags for immediate sitewide use
                setLdFlags(flags);

                // we have finished identifying the user, flags are ready for use
                dispatch.user.setIsUserIdentifying(false);
            });
        } catch (err) {
            console.log('ERR', err);
        }
    };

    return <FeatureFlaggerContext.Provider value={{
        featureFlagger: ldClient,
        ldFlags
    }}>
        { children }
    </FeatureFlaggerContext.Provider>;
};

export default FeatureFlaggerProvider;