import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { CheckIcon } from '../icons';
import type { ComponentProps } from 'react';

export type RadioIndicatorProps = ComponentProps<typeof RadixRadioGroup.Indicator>;

export const RadioIndicator = ({ ...props }: RadioIndicatorProps) => (
    <RadixRadioGroup.Indicator {...props}>
        <CheckIcon />
    </RadixRadioGroup.Indicator>
);