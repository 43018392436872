import type { ApiUserAuth } from '@models/api';
import { getCookie } from '@v2';
import HallowCookies from '@models/Cookies';
import type { HallowQueries } from '@models/Queries';
import type { IncomingMessage } from 'http';
import { isApiError } from './errors';
import type { NextRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';
import type { RematchDispatch } from '@rematch/core';
import type { RootModel } from '@store/models';
import { store } from '@store/store';
import { uuid } from '.';
import type { ApiCartBody } from '@models/api/apiHallow';
import { apiGetPlans, apiPostCart, apiUpdateUser } from './api';
import { isRedeemEligible, processRedeemOffer } from './subscriptions';
import { logError, logWarn } from './loggers';

const { dispatch, getState } = store;

export const isObject = (obj: any): boolean => obj && typeof obj === 'object';

const upperFirst = (string) => string.slice(0, 1).toUpperCase() + string.slice(1, string.length);

const checkRedirectQueries = (redirect: string, queries: ParsedUrlQuery, redirectQueries: string[]) => {
    const redirectWithoutPortalHash = redirect.replace('/#/', '/');
    const url = new URL(redirectWithoutPortalHash);

    for (const query of redirectQueries) {
        if (url.searchParams.has(query) && !queries[query]) {
            const queryValue = url.searchParams.get(query);

            if (query === 'referrer') {
                dispatch.order.setIsOrderUpdating(true);
                dispatch.order.updateCodeState(queryValue);
            } else {
                const setter = `set${ query
                    .split('_')
                    .map((str) => upperFirst(str))
                    .join('') }`;
                dispatch.session[setter](queryValue);
            }
        }
    }
};

export const saveUrlQueries = (
    queries: ParsedUrlQuery,
    dispatch: RematchDispatch<RootModel>
) => {
    if (queries.parish) dispatch.session.setParish(queries.parish);

    // applies to this session only, determines where to send a user / whether to enter an AB test
    if (queries.interest) dispatch.session.setInterest(queries.interest);

    if (queries.referrer) {
        const curCode = getState().order?.code?.code;
        if (!curCode || (curCode && curCode !== queries.referrer)) {
            dispatch.order.setIsOrderUpdating(true);
            dispatch.order.updateCodeState(queries.referrer as string);
        }
    }

    if (queries.campaign_name) dispatch.session.setCampaignName(queries.campaign_name);
    if (queries.referral_name) dispatch.session.setReferralName(queries.referral_name);

    if (queries.utm_source) dispatch.session.setUtmSource(queries.utm_source);
    if (queries.utm_medium) dispatch.session.setUtmMedium(queries.utm_medium);
    if (queries.utm_campaign) dispatch.session.setUtmCampaign(queries.utm_campaign);

    if (queries.loginRedirect) {
        dispatch.session.setLoginRedirect(queries.loginRedirect);
        checkRedirectQueries(queries.loginRedirect as string, queries, [
            'referrer',
            'utm_source',
            'utm_medium',
            'utm_campaign'
        ]);
    }
};

export const getQueries = (): HallowQueries => {
    if (typeof window === 'undefined') return {};

    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams) {
        const queries: HallowQueries = {};
        searchParams.forEach((v, k) => queries[k] = searchParams.get(k));
        return queries;
    }

    return {};
};

export const loginRedirect = async (router: NextRouter, intl, user: ApiUserAuth = null, isNew = false) => {
    if (getState().user?.isUserIdentifying) await waitForFeatureFlaggerToIdentify();

    if (getState().session?.activeRedeemFlow) {
        dispatch.session.setActiveRedeemFlow(false);
        await processRedeemOffer(router, intl);
    } else if (getState().session?.activeOnboardingFlow) {
        router.push({ pathname: router.pathname, query: { ...router.query, page: 'subscribe' } });
        dispatch.session.setActiveOnboardingFlow(false);
    } else if (getState().session?.activeSuperBowlFlow) {
        dispatch.session.setActiveSuperBowlFlow(false);
        return window.location.href = `${ window.location.origin }/onboarding/sb/subscribe`;
    } else {
        const loginRedirect = getState().session.loginRedirect;
        const code = getState().order?.code?.code;
        let nextPage: string;

        if (loginRedirect) {
            const loginRedirectWithoutPortalHash = loginRedirect.replace('/#/', '/');

            const url = new URL(loginRedirectWithoutPortalHash);

            const hasPortalRedirect = url.searchParams.has('redirect');
            const hasReferrer = url.searchParams.has('referrer');
            const hasOnboardingPage = url.searchParams.has('page');
            const hasTeachersSite = url.hostname === 'teachers.hallow.com';
            const includesCampaign = url.searchParams.get('showcase') === 'campaign' || url.pathname.includes('campaigns');
            const baseDomain = url.hostname
                .split('.')
                .slice(-2)
                .join('.');
            const isHallow = ['hallow.com', 'hallow.app'].includes(baseDomain);

            // only use loginRedirect if 1) we are logging in, or 2) we register with a referrer code,
            // 3) we register with a specific redirect, 4) we register and want to return to a specific
            // onboarding page (ie sub screen), or 5) we register and want to return to the teachers site
            if ((process.env.ACCESS_AUTH_HEADER || isHallow)
                && (!isNew
                    || (isNew && hasPortalRedirect)
                    || (isNew && hasReferrer)
                    || (isNew && hasOnboardingPage)
                    || (isNew && hasTeachersSite)
                    || (isNew && includesCampaign)
                )
            ) {
                nextPage = loginRedirect;
                // if we auth with a stored code, attach code to loginRedirect
                if (code && !hasReferrer) nextPage = `${ nextPage }referrer=${ code }`;
            }
        }

        if (!nextPage) {
            // if we auth with a code, send user to subscribe page with code attached
            if (code) nextPage = `/redeem?referrer=${ code }`;

            // if no particular redirect, registrations go to onboarding
            else if (isNew) nextPage = `${ window.location.origin }/onboarding`;

            // if no redirect at all, go to Web V2
            else nextPage = `${ process.env.ACCESS_URL_WEB_APP }/home`;
        }

        return window.location.href = nextPage;
    }
};

const waitForFeatureFlaggerToIdentify = async () => new Promise<void>((res) => {
    const start = (new Date).getTime();
    const redirectInterval = setInterval(() => {
        if (!getState().user.isUserIdentifying) {
            clearInterval(redirectInterval);
            res();
        }

        const timer = (new Date).getTime() - start;
        if (timer > 10000) {
            clearInterval(redirectInterval);
            logError('User did not finish identifying with LD. Redirecting user...');
            res();
        }
    }, 300);
});

export const onKeyDown = (
    eventKey: string,
    checkKey: string,
    cb: (arg?: any) => any,
    conditional: boolean = null
) => {
    if (eventKey === checkKey) {
        if (conditional !== null) {
            if (conditional) cb();
        } else cb();
    }
};

export const getServerOrigin = (req: IncomingMessage) => {
    const nextApi = `${ process.env.NODE_ENV === 'development' ? 'http' : 'https' }://${ req.headers.host }`;
    return nextApi;
};

export const addUtms = (user: any) => {
    const session = store.getState().session;
    if (session.utmSource) user.utm_source = session.utmSource;
    if (session.utmMedium) user.utm_medium = session.utmMedium;
    if (session.utmCampaign) user.utm_campaign = session.utmCampaign;

    return user;
};

export const addConsent = (user: any, isSSO: boolean = false) => {
    const consent = JSON.parse(localStorage.getItem('consent'));
    const hasConsentUpdated = sessionStorage.getItem('hasConsentUpdated');

    if (isSSO) {
        if (hasConsentUpdated && consent) user.consent = consent;
    } else if (consent) user.consent = consent;

    return user;
};

export const completeSSORegistration = async () => {
    const newUser: any = {};

    // on registration, if past consent is present in localStorage add to new user
    const hasConsentUpdated = sessionStorage.getItem('hasConsentUpdated');
    if (!hasConsentUpdated) {
        const consent = JSON.parse(localStorage.getItem('consent'));
        if (consent) newUser.consent = consent;
    }

    await apiUpdateUser(newUser);
};

export const onSSOFailure = (reason, intl, setButtonLoading) => {
    setButtonLoading(null);

    logError(reason);
    if (process.env.NODE_ENV === 'development') {
        logWarn('Login failure detected. Google triggers an error when loading on invalid domains. Our dev env logs this message instead.');
    } else {
        logError(intl.formatMessage({ id: 'login_social_auth_error_message', defaultMessage: 'We are unable to log you in. Please try again.' }));
    }
};

export const onUserAuth = async (data: ApiUserAuth) => {
    // trigger LD identify()
    dispatch.user.setIsUserIdentifying(true);

    // save user data
    dispatch.user.updateUserState({ ...data.user, oauth: data.oauth });
    if (data?.is_new) completeSSORegistration();

    try {
        const products = await apiGetPlans();
        if (isApiError(products)) throw products;

        const body: ApiCartBody = { price_id: getState().order?.plan?.price.id };
        if (getState().order?.code?.code && isRedeemEligible(data.user)) body.promo_code = getState().order.code.code;

        const cart = await apiPostCart(body);
        if (isApiError(cart)) throw cart;

        return {};
    } catch (err) {
        if (err.errors?.[0]) err.errors[0].callName = 'apiPostCart';
        logError('onUserAuth', err);
        return err;
    }
};

export const alignAnonymousids = () => {
    if (typeof window !== undefined && window?.hallowAnonymousUserId) return window.hallowAnonymousUserId;

    const anonUserIdCookie = getCookie({ key: HallowCookies.AJS_ANONYMOUS_ID });
    const lDGuestKeyCookie = getCookie({ key: HallowCookies.LD_GUEST_KEY }) ;
    const sessionStorageId = typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('LDGuestKey') : null;

    const anonId = (anonUserIdCookie ?? lDGuestKeyCookie ?? sessionStorageId ?? uuid()).replaceAll('"', '');

    window.hallowAnonymousUserId = anonId;
    return anonId;
};

export const getImagePath = (imageName) => {
    return process.env.ACCESS_S3_HALLOW_IMAGES + imageName;
};