import type { ApiPlan } from '@models/api/apiHallow';
import { createModel } from '@rematch/core';
import type { RootModel } from './models';
import type { RootState } from './store';

type SessionState = {
    activeOnboardingFlow: boolean
    activeRedeemFlow: boolean
    activeSuperBowlFlow: boolean
    campaignName: string
    errorToDisplay: string
    interest: string
    isHighPrivacyZone: boolean
    isUsUser: boolean // temp state to handle WhatsApp sender limit
    locale: string
    loginRedirect: string // urlencoded absolute url
    parish: string // hdp state (from WP params)
    plans: Array<ApiPlan>
    defaultApiPlan: ApiPlan // use to 'reset' selected plan
    redeemedSubDetails: any // use to display sub details after purchase/resetting store
    referralName: string // first name of person / entity who referred user
    subscriptionPayAnyAmount: null | number;
    subscriptionPayAnyAmountPriceId: null | string;
    utmSource: string
    utmMedium: string
    utmCampaign: string
}

export const session = createModel<RootModel>()({
    state: { isHighPrivacyZone: true } as SessionState,
    reducers: {
        setSessionState: (state, payload) => ({
            ...state,
            ...payload
        }),
        setActiveOnboardingFlow: (state, payload) => ({
            ...state,
            activeOnboardingFlow: payload
        }),
        setActiveRedeemFlow: (state, payload) => ({
            ...state,
            activeRedeemFlow: payload
        }),
        setActiveSuperBowlFlow: (state, payload) => ({
            ...state,
            activeSuperBowlFlow: payload
        }),
        setCampaignName: (state, payload) => ({
            ...state,
            campaignName: payload
        }),
        setErrorToDisplay: (state, payload) => ({
            ...state,
            errorToDisplay: payload
        }),
        setInterest: (state, payload) => ({
            ...state,
            interest: payload
        }),
        setIsHighPrivacyZone: (state, payload) => ({
            ...state,
            isHighPrivacyZone: payload
        }),
        setIsUsUser: (state, payload) => ({
            ...state,
            isUsUser: payload
        }),
        setLocale: (state, payload) => ({
            ...state,
            locale: payload
        }),
        setLoginRedirect: (state, payload) => ({
            ...state,
            loginRedirect: payload
        }),
        setParish: (state, payload) => ({
            ...state,
            parish: payload
        }),
        setPlans: (state, payload) => ({
            ...state,
            plans: payload
        }),
        setDefaultApiPlan: (state, payload) => ({
            ...state,
            defaultApiPlan: payload
        }),
        setRedeemedSubDetails: (state, payload) => ({
            ...state,
            redeemedSubDetails: payload
        }),
        setReferralName: (state, payload) => ({
            ...state,
            referralName: payload
        }),
        setSubscriptionPayAnyAmount: (state, payload) => ({
            ...state,
            subscriptionPayAnyAmount: payload
        }),
        setSubscriptionPayAnyAmountPriceId: (state, payload) => ({
            ...state,
            subscriptionPayAnyAmountPriceId: payload
        }),
        setUtmSource: (state, payload) => ({
            ...state,
            utmSource: payload
        }),
        setUtmMedium: (state, payload) => ({
            ...state,
            utmMedium: payload
        }),
        setUtmCampaign: (state, payload) => ({
            ...state,
            utmCampaign: payload
        })
    },
    effects: (dispatch) => ({
        updatePlans(plansArray) {
            dispatch.session.setPlans(plansArray);
            
            const defaultApiPlan = plansArray.filter((plan) => plan.external_id === 'yearly_full_subscription')[0];
            dispatch.session.setDefaultApiPlan(defaultApiPlan);

            dispatch.order.setPlan(defaultApiPlan);
        }
    })
});

export const selectErrorToDisplay = (state: RootState) => state.session?.errorToDisplay;
export const selectInterest = (state: RootState) => state.session?.interest;
export const selectIsHighPrivacyZone = (state: RootState) => state.session?.isHighPrivacyZone;
export const selectIsUsUser = (state: RootState) => state.session?.isUsUser;
export const selectLocale = (state: RootState) => state.session?.locale;
export const selectPlans = (state: RootState) => state.session?.plans;
export const selectActiveOnboardingFlow = (state: RootState) => state.session?.activeOnboardingFlow;
export const selectActiveRedeemFlow = (state: RootState) => state.session?.activeRedeemFlow;
export const selectActiveSuperBowlFlow = (state: RootState) => state.session?.activeSuperBowlFlow;
export const selectRedeemedSubDetails = (state: RootState) => state.session?.redeemedSubDetails ?? {};
export const selectParish = (state: RootState) => state.session?.parish;
export const selectCampaignName = (state: RootState) => state.session?.campaignName ?? '';
export const selectReferralName = (state: RootState) => state.session?.referralName ?? '';
export const selectSubscriptionPayAnyAmount = (state: RootState) => state.session?.subscriptionPayAnyAmount ?? null;
export const selectSubscriptionPayAnyAmountPriceId = (state: RootState) => state.session?.subscriptionPayAnyAmountPriceId ?? null;
export const selectDefaultApiPlan = (state: RootState) => state.session?.defaultApiPlan;