import { parse } from 'cookie';
import TagManager from 'react-gtm-module-nonce-aware';
import { useEffect, useMemo } from 'react';

/*
    Setup Google Tag Manager
*/
const GTMProvider = ({ children }) => {
    useEffect(() => {
        const cookies = parse(document.cookie || '');
        const gtmNonce = cookies?.gtmNonce;

        if (gtmNonce && gtmNonce?.length) {
            document.head.childNodes.forEach((node) => {
                if ((node as any)?.innerHTML?.includes('googletagmanager')) document.head.removeChild(node);
            });
            document.body.childNodes.forEach((node) => {
                if ((node as any)?.innerHTML?.includes('googletagmanager')) document.body.removeChild(node);
            });

            TagManager.initialize({
                gtmId: process.env.ACCESS_GOOGLE_TAG_MANAGER_ID,
                nonce: gtmNonce,
                elementId: 'gtmScript'
            });
        }
    }, []);

    return useMemo(() => children, [children]);
};

export default GTMProvider;