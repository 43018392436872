import AuthenticationMethods from '@components/AuthenticationMethods';
import { AuthFlow } from '@v2/@packages/ui';
import { COLORS } from '@models/Colors';
import NextNProgress from 'nextjs-progressbar';
import React from 'react';
import s from './authentication.module.scss';

type AuthenticationProps = {
    variant?: AuthFlow
}

const Authentication = ({ variant = 'default' }: AuthenticationProps) => (
    <div className={s.pageIndex}>
        <NextNProgress color={COLORS.PURPLE} />
        <AuthenticationMethods variant={variant} />
    </div>
);

export default Authentication;