import { BUTTON_TYPE } from '@models/components';
import clsx from 'clsx';
import s from './button.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
    buttonClass?: string
    buttonIconSrc?: string
    buttonType?: BUTTON_TYPE
    disabled?: boolean
    loading?: boolean
    onClick?: (arg?: any) => any
    intlText?: string
    text?: string
    textValue0?: string
    textValue1?: string
    type?: 'button'|'submit'
}

const Button = ({
    buttonClass,
    buttonIconSrc,
    buttonType,
    disabled = false,
    loading = false,
    onClick = null,
    intlText = null,
    text = 'dialog_feedback_submit',
    textValue0 = null,
    textValue1 = null,
    type = 'button'
}: Props) => {
    const intl = useIntl();

    let buttonStyle;
    switch (buttonType) {
        case BUTTON_TYPE.TEXT: {
            buttonStyle = s.text;
            break;
        }
        case BUTTON_TYPE.APPLE: {
            buttonStyle = s.apple;
            break;
        }
        case BUTTON_TYPE.GOOGLE: {
            buttonStyle = s.google;
            break;
        }
        case BUTTON_TYPE.WHATS_APP: {
            buttonStyle = s.whatsApp;
            break;
        }
        default: {
            buttonStyle = null;
        }
    }

    return (
        <button
            className={clsx('hallowButton', buttonStyle && buttonStyle, buttonClass && buttonClass, loading && 'loading')}
            disabled={disabled || loading}
            onClick={onClick}
            type={type}>
            {buttonIconSrc && (
                <img
                    src={buttonIconSrc}
                    alt={intl.formatMessage({ id: 'app_name', defaultMessage: 'Hallow' })}
                    className={s.buttonIcon}
                />
            )}
            {
                intlText ?? <FormattedMessage id={text} defaultMessage={'Submit'} values={{ 0: textValue0, 1: textValue1 }} />
            }
        </button>
    );
};

export default Button;