import type { Models } from '@rematch/core';
import { order } from './orderModel';
import { session } from './sessionModel';
import { user } from './userModel';
 
export interface RootModel extends Models<RootModel> {
  order: typeof order,
  session: typeof session,
  user: typeof user
}
 
export const models: RootModel = { order, session, user };