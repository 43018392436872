import * as RadixUIAccessibleIcon from '@radix-ui/react-accessible-icon';
import { capitalizeFirstLetter } from '@v2/@packages/sdk';
import { HallowElement, HallowElementProps } from '@v2/@packages/ui';
import { HallowIconFilledAndOutline, HallowIconFilledOrOutline } from './types';
import { iconPathDsGeneralFilledAndOutline, iconPathDsGeneralFilledOrOutline, iconPathDsNavigation } from './iconPathDs';
import React, { forwardRef } from 'react';

const Element: HallowElement = 'svg';

const iconPathDsFilledOrOutline = {
    ...iconPathDsGeneralFilledOrOutline,
    ...iconPathDsNavigation,
};

export type IconProps = Omit<HallowElementProps<typeof Element>, 'type'> & {
  label?: string;
  size?: number;
} & (
    | {
        icon: HallowIconFilledOrOutline;
        type?: 'default';
      }
    | {
        icon: HallowIconFilledAndOutline;
        type?: keyof (typeof iconPathDsGeneralFilledAndOutline)[keyof typeof iconPathDsGeneralFilledAndOutline];
      }
  );

export const Icon = forwardRef<any, IconProps>(
    (
        {
            fill = 'currentColor',
            icon,
            label = capitalizeFirstLetter({ string: icon }),
            size = 24,
            type = 'outline',
            ...props
        },
        ref,
    ) => {
        let determinedIconPathDs = iconPathDsFilledOrOutline[icon];
        if (!determinedIconPathDs) determinedIconPathDs = iconPathDsGeneralFilledAndOutline[icon][type];
        
        return (
            <RadixUIAccessibleIcon.Root label={label}>
                <Element
                    fill={fill}
                    height={size}
                    ref={ref}
                    viewBox={'0 0 24 24'}
                    width={size}
                    xmlns="http://www.w3.org/2000/svg"
                    {...props}
                >
                    {typeof determinedIconPathDs === 'string' ? (
                        <path
                            clipRule="evenodd"
                            d={determinedIconPathDs}
                            fill={fill}
                            fillRule="evenodd"
                        />
                    ) :
                            (determinedIconPathDs as string[]).map((determinedIconPathD) => (
                                <path
                                    clipRule="evenodd"
                                    d={determinedIconPathD}
                                    fill={fill}
                                    fillRule="evenodd"
                                    key={determinedIconPathD}
                                />
                            ))
                    }
                </Element>
            </RadixUIAccessibleIcon.Root>
        );
    }
);

export default Icon;

Icon.displayName = 'Icon';