import * as DialogRadixUI from '@radix-ui/react-dialog';
import styles from './modal.module.scss';
import XIcon from '@components/icons/XIcon';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

export type ModalProps = ComponentPropsWithoutRef<typeof DialogRadixUI.Root> & {
    trigger?: ReactNode;
};

export const Modal = ({ children, trigger, ...props }: ModalProps) => {
    return (
        <DialogRadixUI.Root {...props}>
            {trigger && (
                <DialogRadixUI.Trigger asChild>
                    {trigger}
                </DialogRadixUI.Trigger>
            )}
            <DialogRadixUI.Portal>
                <DialogRadixUI.Overlay className={styles.overlay} />
                <DialogRadixUI.Content className={styles.content}>
                    <div className={styles.closeContainer}>
                        <DialogRadixUI.Close asChild>
                            <button aria-label="Close" className={styles.close}>
                                <XIcon />
                            </button>
                        </DialogRadixUI.Close>
                    </div>
                    {children}
                </DialogRadixUI.Content>
            </DialogRadixUI.Portal>
        </DialogRadixUI.Root>
    );
};