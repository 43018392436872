import { models } from '@store/models';
import persistPlugin from '@rematch/persist';
import type { RootModel } from '@store/models';
import storage from 'redux-persist/lib/storage/session';
import { init, Models, RematchDispatch, RematchRootState, RematchStore } from '@rematch/core';

const persistConfig: any = {
    key: 'root',
    storage
};

export const store: RematchStore<RootModel, Record<string, never>> = init({
    models: models as RootModel,
    plugins: [persistPlugin(persistConfig)]
});

export const dispatch: RematchDispatch<Models<RootModel>> = store.dispatch;

export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>