import { useQuery } from '@tanstack/react-query';
import { keyMe, requestMe } from '../utilities';

export type UseMeProps = {};

export const useMe = ({}: UseMeProps = {}) => {
    const query = useQuery({
        queryFn: () => requestMe().then((res) => res.json()),
        queryKey: keyMe()
    });

    return { query };
};