import { useQuery } from '@tanstack/react-query';
import { keyCollection, requestCollection } from '../utilities';

export type UseCollectionProps = { id: string; };


export const useCollection = ({ id }: UseCollectionProps) => {
    const query = useQuery({
        queryFn: () => requestCollection({ id }).then((res) => res.json()),
        queryKey: keyCollection({ id })
    });

    return { query };
};