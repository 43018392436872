import { Context } from '../../utilities';
import type { ProviderProps } from './types';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';

export type ContextProviderProps = ProviderProps;

export const ContextProvider = ({ children }: ContextProviderProps) => {
    const [cart, setCart] = useState<any>({});

    return (
        <Context.Provider value={{ cart, setCart }}>
            {children}
            <ReactQueryDevtools />
        </Context.Provider>
    );
};